import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
        this.pack.common.addEvery90framesTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
        this.pack.common.addEvery90framesTarget(this);
    }

    destruct(){
        super.destruct();

        for( let i = 0, len = this.uiList.length; i < len; i++ ){
            let ui = this.uiList[i];
            let next = ui.next;
            let prev = ui.prev;
            let btns = ui.btns;
            next.removeEventListener("click", this.clickNextHandler);
            prev.removeEventListener("click", this.clickPrevHandler);

            for( let j = 0; j < btns.length; j++ ){
                let btn = btns[j];
                btn.removeEventListener("click", this.clickBtnHandler);
                btn.removeEventListener("mouseover", this.mouseOverBtnHandler);
                btn.removeEventListener("mouseout", this.mouseOutBtnHandler);
            }
        }

        for( let i = 0, len = this.localMenuList.length; i < len; i++ ){
            let item = this.localMenuList[i];
            item.removeEventListener("click", this.clickLocalMenuHandler);
        }

        this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
        this.pack.common.removeEvery90framesTarget(this);
    }

    setVars(){
        super.setVars();

        this.currentSection = 0;

        this.uiList = [];
        this.contentsList = [];
        this.currentList = [];

        this.insertSectionLen;
        this.sectionChildAllNumber = 0;
        this.addedSectionChildCnt = 0;
        this.detailList = [];
    }

    setDom(){
        super.setDom();

        this.h1 = document.querySelector('main.platform h1');
        this.sections = document.querySelector('main.platform .sections');
        this.sectionsForLocalNav = document.querySelector('main.platform .sections_for_local_nav');
        this.localMenuListUls = document.querySelectorAll('main.platform .sections_for_local_nav .local_nav > ul > li > ul');
        this.releaseNotesContainer = document.querySelector('main.platform  .documentation_update');
        this.documentation = document.querySelector('main.platform .documentation');
    }

    initEvents(){
        super.initEvents();
    }

    setEvents(){
        for( let i = 0, len = this.localMenuList.length; i < len; i++ ){
            let item = this.localMenuList[i];
            item.addEventListener("click", {handleEvent:this.clickLocalMenuHandler, id:i, sc:this});
        }
    }

    setContentsAndEvents(){
        this.childSections = document.querySelectorAll('.child_section');

        for( let i = 0, len = this.childSections.length; i < len; i++ ){
            let section = this.childSections[i];
            let obj = {};

            obj.next = section.querySelector(".next");
            obj.prev = section.querySelector(".prev");
            obj.btnParents = section.querySelectorAll(".item");
            obj.btns = section.querySelectorAll(".item span");
            obj.numerator = section.querySelector(".current .numerator");
            let contents = {};
            contents.media = section.querySelectorAll(".imgs img, .imgs video");
            contents.text = section.querySelectorAll(".items .item");

            this.contentsList.push(contents);
            this.currentList.push(0);
            if(obj.next) this.uiList.push(obj);
        }

        for( let i = 0, len = this.uiList.length; i < len; i++ ){
            let ui = this.uiList[i];
            let next = ui.next;
            let prev = ui.prev;
            let btns = ui.btns;
            next.addEventListener("click", {handleEvent:this.clickNextHandler, id:i, sc:this});
            prev.addEventListener("click", {handleEvent:this.clickPrevHandler, id:i, sc:this});

            for( let j = 0; j < btns.length; j++ ){
                let btn = btns[j];
                btn.addEventListener("click", {handleEvent:this.clickBtnHandler, id:i, id2:j, sc:this});
                btn.addEventListener("mouseover", {handleEvent:this.mouseOverBtnHandler, id:i, id2:j, sc:this});
                btn.addEventListener("mouseout", {handleEvent:this.mouseOutBtnHandler, id:i, id2:j, sc:this});
            }
        }
    }

    clickLocalMenuHandler(event){
        let sc = this.sc;
        let id = this.id;

        sc.pack.anchorScroll(window.pageYOffset + sc.sectionList[id].getBoundingClientRect().top - 200);
    }


    clickPrevHandler(event){
        let sc = this.sc;
        let id = this.id;

        let media = sc.contentsList[id].media;
        let text = sc.contentsList[id].text;
        let current = sc.currentList[id];
        let firstMedia = media[0];
        let firstText = text[0];

        if(current === 0) return;
        current--;
        sc.currentList[id] = current;
        sc.slide(firstMedia, firstText, -100 * current +"%");
        sc.setCurrent(id, current);
    }

    clickNextHandler(event){
        let sc = this.sc;
        let id = this.id;

        let media = sc.contentsList[id].media;
        let text = sc.contentsList[id].text;
        let current = sc.currentList[id];
        let len = media.length;
        let firstMedia = media[0];
        let firstText = text[0];

        if(current === len - 1) return;
        current++;
        sc.currentList[id] = current;
        sc.slide(firstMedia, firstText, -100 * current +"%");
        sc.setCurrent(id, current);
    }

    clickBtnHandler(event){
        let sc = this.sc;
        let id = this.id;
        let id2 = this.id2;

        let media = sc.contentsList[id].media;
        let text = sc.contentsList[id].text;

        let current = sc.currentList[id];
        let firstMedia = media[0];
        let firstText = text[0];

        current = id2;
        sc.currentList[id] = current;

        sc.slide(firstMedia, firstText, -100 * current +"%");
        sc.setCurrent(id, current);
    }

    mouseOverBtnHandler(event){
        let sc = this.sc;
        let id = this.id;
        let id2 = this.id2;
        let item = sc.uiList[id];
        let btns = item.btnParents;
        btns[id2].classList.add("hover");
    }

    mouseOutBtnHandler(event){
        let sc = this.sc;
        let id = this.id;
        let id2 = this.id2;
        let item = sc.uiList[id];
        let btns = item.btnParents;
        btns[id2].classList.remove("hover");
    }

    slide(targetMedia, targetText, position){
        TweenMax.killTweensOf([targetMedia, targetText]);
        TweenMax.to([targetMedia, targetText], .7, {marginLeft:position, ease:Quart.easeInOut});
    }

    setCurrent(id, current){
        let item = this.uiList[id];
        let btns = item.btnParents;
        let numerator = item.numerator;

        numerator.innerText = this.pack.addZero(String(current + 1));

        for( let i = 0, len = btns.length; i < len; i++ ){
            btns[i].classList.remove("current_mark");
        }

        btns[current].classList.add("current_mark");
    }

    start(){
        TweenMax.delayedCall(.9, ()=>{
            this.h1.classList.add('show');
        });
    }

    checkCurrentSection(){
        let current = 0;
        for( let i = 0, len = this.sectionList.length; i < len; i++ ){
            let section = this.sectionList[i];
            let top = section.getBoundingClientRect().top;
            if(top < this.sh) current = i;
        }

        if(current != this.currentSection){
            this.currentSection = current;
            this.changeCurrentSection();
        }
    }

    checkH2Position(){
        let current = -1;
        for( let i = 0, len = this.sectionH2List.length; i < len; i++ ){
            let h2 = this.sectionH2List[i];
            let top = h2.getBoundingClientRect().top;
            if(top < this.sh - 200) current = i;
        }

        if(current < 0) return;

        if(!this.sectionList[current].classList.contains("show")){
            this.sectionList[current].classList.add("show");
        }
    }

    checkH3Position(){
        let current = -1;

        for( let i = 0, len = this.sectionH3List.length; i < len; i++ ){
            let h3 = this.sectionH3List[i];
            let top = h3.getBoundingClientRect().top;
            if(top < this.sh - 100) current = i;
        }

        if(current < 0) return;

        if(!this.sectionH3List[current].classList.contains("show")){
            this.sectionH3List[current].classList.add("show");
        }
    }

    changeCurrentSection(){
        for( let i = 0, len = this.localMenuList.length; i < len; i++ ){
            let menu = this.localMenuList[i];
            // let paper = this.localMenuPaperList[i];
            menu.classList.remove("current");
            // paper.classList.remove("current");
        }

        this.localMenuList[this.currentSection].classList.add("current");
        // this.localMenuPaperList[this.currentSection].classList.add("current");
    }

    setSection(id) {
        let items = this["contents" + id + "List"];
        let container = this.sectionContainerList[id - 1];
        let mediaList = this["contents" + id + "MediaAPIPathList"];
        let domList = this["contents" + id + "DomList"];
        this.sectionChildAllNumber += items.length;

        for( let i = 0, len = items.length; i < len; i++ ){
            let item = items[i];
            let list = mediaList[i] = [];
            let dom = this.getChildSection(item, list);
            domList.push(dom);
            if(dom) container.appendChild(dom);
            this.getMedias(list, id, i);
        }
    }

    setEachSection(items, nameStr, headlineStr){
        let navSrc = '';
        for( let i = 0, len = items.length; i < len; i++ ){
            let contentsID = i + 1;
            let item = items[i];
            let name = this.pack.getData(item.fields, nameStr);
            let headlineEn = item.fields[headlineStr + 'En'];
            let headlineJa = item.fields[headlineStr + 'Ja'];
            let section = this.getSection(i, name, headlineEn, headlineJa);
            this.sections.insertBefore(section, this.documentation);
            navSrc += this.getLocalNavItem(i, name);
            this["contents" + contentsID + "List"] = [];
            this["contents" + contentsID + "MediaAPIPathList"] = [];
            this["contents" + contentsID + "MediaPathList"] = [];
            this["contents" + contentsID + "DomList"] = [];
        }

        this.localMenuListUls[0].innerHTML = navSrc;
        this.localMenuList = document.querySelectorAll('main.platform .sections_for_local_nav .local_nav > ul > li > ul > li');
        this.sectionList = document.querySelectorAll('main.platform .sections .section');
        this.sectionContainerList = document.querySelectorAll('main.platform .sections .section .section_child');
        this.sectionH2List = document.querySelectorAll('main.platform .sections .section h2');
        this.setEvents();
    }

    setValues(carousellist, sectionChildlist){
        let items = carousellist;
        for( let i = 0, len = items.length; i < len; i++ ){
            let item = items[i];
            let fields = item.fields;
            let relatedID = item.sys.id;
            let mediaSysID = this.pack.getData(fields, "media").sys.id;
            let description = this.pack.getDescription(fields);
            let title = this.pack.getTitle(fields);
            this.detailList[i] = {title:title, relatedID:relatedID, mediaSysID:mediaSysID, description:description};
        }

        items = sectionChildlist;
        this.insertSectionLen = items.length;

        for( let i = 0; i < this.insertSectionLen; i++ ){
            let sectionItems = items[i].items;
            let contentsID = i + 1;
            for( let j = 0, len = sectionItems.length; j < len; j++ ){
                let item = sectionItems[j].fields;
                let displayOrder = item.displayOrder;
                let details = item.details;
                let mediaIDList = [];
                let tit = item.toolAboutText;
                let description = this.pack.getData(item, "toolDescription");

                for( let k = 0; k < details.length; k++ ){
                    let detail  = details[k];
                    let id = detail.sys.id;
                    mediaIDList.push(id);
                }

                // trace(item);
                // trace(displayOrder, contentsID, tit, description);

                this["contents" + contentsID + "List"][displayOrder - 1] = {tit:tit, description:description, mediaIDList:mediaIDList};
            }
        }
    }

    getLocalNavItem(id, str){
        let src = '';
        if(id === 0){
            src += '<li class="current">';
        }else{
            src += '<li>';
        }

        for( let i = 0, len = str.length; i < len; i++ ){
            let value = str.substr(i, 1);

            if(value === " ") src += '<span class="item space">';
            else src += '<span class="item">';
            src += value;
            src += '</span>';
        }

        src += '</li>';

        return src;
    }

    getSection(id, name, headlineEn, headLineJa){
        let idPlus = id + 1;
        let addClassSrc = 'section' + idPlus;
        let addIDSrc = 'section' + idPlus;
        let nameSrc = '';
        let headlineSrc = '';
        let headLineArray = headlineEn.split("<br>");

        for( let i = 0, len = name.length; i < len; i++ ){
            let value = name.substr(i, 1);
            nameSrc += '<span>';
            nameSrc += value;
            nameSrc += '</span>';
        }

        for( let h = 0; h < headLineArray.length; h++ ){
            let headline = headLineArray[h];
            headlineSrc += '<span class="span_chunk">';
            for( let i = 0, len = headline.length; i < len; i++ ){
                let value = headline.substr(i, 1);
                if(value === " ") headlineSrc += '<span class="item space">';
                else headlineSrc += '<span class="item">';
                headlineSrc += value;
                headlineSrc += '</span>';
            }
            headlineSrc += '</span>';
        }

        if(name === 'CONFIGURATION') addIDSrc = 'configuration';
        else if(name === 'SIMULATION') addIDSrc = 'simulation';
        else if(name === 'FLEET MANAGEMENT') addIDSrc = 'fleet_management';

        let japaneseTit = '';

        if(this.pack.LANG === 'ja'){
            japaneseTit = `
                <span class="font2 tit_ja">${headLineJa}</span>
            `;
        }

        let src = `
            <section class="section ${addClassSrc}" id="${addIDSrc}">
                <div class="section_child">
                    <h2>
                        <span class="sub_tit">
                            <span class="span_chunk">
                                ${nameSrc}
                            </span>
                        </span>
                        <span class="tit">
                            ${headlineSrc}
                        </span>
                        ${japaneseTit}
                    </h2>
                </div>
            </section>
        `;

        const tempEl = document.createElement('div');
        tempEl.innerHTML = src;
        return tempEl.firstElementChild;
    }

    getChildSection(data, mediaList){
        if(!data) return;

        let tit = data.tit;
        let description = data.description;

        let childSection = document.createElement("section");
        childSection.classList.add("child_section");

        //VECTOR MAP BUILDER用アンカーリンク付与
        if(tit === "VECTOR MAP BUILDER") {
            childSection.setAttribute("id", "vector_map_builder");
        }else if(tit === "SCENARIO EDITOR") {
            childSection.setAttribute("id", "scenario_editor");
        }else if(tit === '3D MAPPING') {
            childSection.setAttribute("id", "three_d_mapping");
        }

        let titSrc = '';
        for( let i = 0, len = tit.length; i < len; i++ ){
            titSrc += '<span>' + tit[i] + '</span>';
        }

        let mediaIDList = data.mediaIDList;
        let detailLen = mediaIDList.length;
        let detailLenString = this.pack.addZero(String(detailLen));
        let details = [];

        for( let i = 0, len = mediaIDList.length; i < len; i++ ){
            let id = mediaIDList[i];
            this.detailList.filter((value)=>{
                if(id === value.relatedID) {
                    details.push(value);
                    mediaList.push(this.pack.getContentfulAPIImgAccessPath(value.mediaSysID));
                }
            });
        }

        let className = "";
        if(details.length > 1) className = " patern" + detailLen;
        let barSrc = `<div class="bar${className}">`;
        let contentsSrc = "";

        for( let i = 0, len = detailLen; i < len; i++ ){
            let detail = details[i];
            let className = "";
            let title = detail.title;
            let description = detail.description;
            if(i === 0) className = " current_mark";
            barSrc += `            
                <div class="item${className}">
                    <span></span>
                </div>
            `;

            contentsSrc += `
                <div class="item">
                    <div class="title">${title}</div>
                    <div class="text">${description}</div>
                </div>
            `;
        }
        barSrc += `</div>`;

        let src = `            
            <div class="chunk">
                <h3>${titSrc}</h3>
                <p class="txt">${description}</p>
            </div>
            <div class="imgs">

            </div>
            <div class="img_ui">
                
                ${barSrc}
                
                <div class="current">
                    <div class="numerator">01</div>
                    <div class="denominator">${detailLenString}</div>
                </div>
                <div class="prev_next">
                    <div class="prev"></div>
                    <div class="next"></div>
                </div>
            </div>
            <div class="items">
                ${contentsSrc}
            </div>
            
        `;

        childSection.innerHTML = src;
        return childSection;
    }

    getMedias(list, id1, id2) {
        let urlList = list;
        let promiseList = [];
        this["contents" + id1 + "MediaPathList"][id2] = [];
        urlList.forEach ((url, i) => {
            promiseList.push (
                fetch(url).then ((res) => {
                    return res.json();
                }).then( (res) => {
                    this["contents" + id1 + "MediaPathList"][id2][i] = res;
                })
            );
        });

        Promise
            .all(promiseList)
            .then (()=> {
                // trace("Media path loaded", this["contents" + id1 + "MediaPathList"][id2]);
                this.setMedias(id1, id2);
            });
    }

    setMedias(id1, id2){
        let dom = this["contents" + id1 + "DomList"][id2];
        let data = this["contents" + id1 + "MediaPathList"][id2];
        let protocol = location.protocol;
        if(dom){
            let mediaContainer = dom.querySelector(".imgs");
            let html = ``;

            for( let i = 0, len = data.length; i < len; i++ ){
                let url = protocol + data[i].fields.file.url;
                if(url.indexOf(".mp4") >= 0) html += `<video src="${url}" autoplay="" loop="" muted="" webkit-playsinline="" playsinline="" poster=""></video>`;
                else html += `<img src="${url}" alt="">`;
            }

            mediaContainer.innerHTML = html;
        }

        this.addedSectionChildCnt++;
        if(this.addedSectionChildCnt === this.sectionChildAllNumber) this.setContentsAndEvents();
        // trace(this.sectionChildAllNumber, this.addedSectionChildCnt, id1, id2);
    }

    scrollHandler(){
        this.checkCurrentSection();
        this.checkH2Position();
        this.checkH3Position();
    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    enterEvery90Frames(){
        this.executeResize();
    }

    executeResize() {
        super.executeResize();
        this.sectionsForLocalNav.style.height = this.sections.clientHeight - this.sh + "px";
    }
}