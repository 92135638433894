import TemplateContents from './TemplateContents';
import Carousel from './Carousel';

export default class extends TemplateContents {
    constructor(param) {
        super(param);
    }

    init() {
        super.init(true);
        this.resizeEvent = 'resize';

        this.initEvents();

        this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    setCarousel() {
        this.carousel.setTarget(this.section4);
        this.carousel.setMarginRightPC(true, 4.1666666667); //4.1666666667vw
        this.carousel.setMarginRightSP(true, 6.1333333333); //6.1333333333vw
        this.carousel.setAdjustMinXPC(true, 8.25); //8.25vw
        this.carousel.setAdjustMinXSP(true, 8.25); //8.25vw
    }

    reset() {
        super.reset();
        this.setVars();
        // this.setCarousel();
        this.pack.hero.reset();

        this.pack.common.addScrollTarget(this);
        this.pack.common.addEnterframeTarget(this);
    }

    destruct() {
        super.destruct();
        this.lockScroll = false;
        this.enabledScroll = false;

        document.removeEventListener(
            this.mousewheelEvent,
            this.bindWheelHandler
        );
        document.removeEventListener('touchstart', this.bindTouchStartHandler);
        document.removeEventListener('touchmove', this.bindTouchMoveHandler);
        document.removeEventListener('touchend', this.bindTouchEndHandler);

        this.pack.hero.removeEventListener(
            'openingEnded',
            this.bindOpeningEndedHeroHandler
        );
        this.pack.hero.removeEventListener(
            'changedScene',
            this.bindChangedHeroHandler
        );

        this.heroSkip.removeEventListener(
            'click',
            this.bindClickHeroSkipHandler
        );

        // this.carousel.destruct();

        this.pack.header.showHeader();

        this.pack.common.removeScrollTarget(this);
        this.pack.common.removeEnterframeTarget(this);
    }

    destructAfterContentsOut() {
        this.pack.hero.removeHero(this.heroCanvasContainer);
        this.pack.hero.destruct();
    }

    setVars() {
        super.setVars();

        this.loadedContentful = false;
        this.lockScroll = true; //スクロールを無効にするかどうか
        this.enabledScroll = false;
        this.currentHero = 1;
        this.pastHero = this.currentHero;
        this.HERO_SCENE_LEN = 10;

        this.touchStartPoint = 0;

        this.ourTeamList = [];
        this.ourTeamImgAccessAPIPathList = [];

        this.ourPartnerList = [];
        this.ourPartnerImgAccessAPIPathList = [];

        this.isWaitingBackHeroScene8 = false;

        this.prevWheelTime = Date.now();
        this.prevWheelDelta = 0;
    }

    setDom() {
        super.setDom();

        this.heroCanvasContainer = document.querySelector(
            'main.top .hero .canvasContainer'
        );
        this.heroContents = document.querySelectorAll('.hero .common_contents');
        this.heroScroll = document.querySelector('main.top .hero .scroll');
        this.heroNumerator = document.querySelector(
            'main.top .hero .scroll .numerator'
        );
        this.heroCreateAccount = document.querySelector(
            'main.top .hero .create_account'
        );
        this.heroSkip = document.querySelector('main.top .hero .skip');

        this.heroCont4H3 = document.querySelector(
            'main.top .hero .contents .contents4 h3'
        );
        this.heroCont5H3 = document.querySelector(
            'main.top .hero .contents .contents5 h3'
        );
        this.heroCont6H3 = document.querySelector(
            'main.top .hero .contents .contents6 h3'
        );
        this.heroCont7H3 = document.querySelector(
            'main.top .hero .contents .contents7 h3'
        );
        this.heroCont8H3 = document.querySelector(
            'main.top .hero .contents .contents8 h3'
        );
        this.heroCont9H3 = document.querySelector(
            'main.top .hero .contents .contents9 h3'
        );

        this.heroCont4Txt = document.querySelector(
            'main.top .hero .contents .contents4 .txt'
        );
        this.heroCont5Txt = document.querySelector(
            'main.top .hero .contents .contents5 .txt'
        );
        this.heroCont6Txt = document.querySelector(
            'main.top .hero .contents .contents6 .txt'
        );
        this.heroCont7Txt = document.querySelector(
            'main.top .hero .contents .contents7 .txt'
        );
        this.heroCont8Txt = document.querySelector(
            'main.top .hero .contents .contents8 .txt'
        );
        this.heroCont8Chunk = document.querySelector(
            'main.top .hero .contents .contents8 .chunk'
        );
        this.heroCont9Txt = document.querySelector(
            'main.top .hero .contents .contents9 .txt'
        );

        this.heroCont3Btn = document.querySelector(
            'main.top .hero .contents .contents3 .btn'
        );
        this.heroCont4Btn = document.querySelector(
            'main.top .hero .contents .contents4 .btn'
        );
        this.heroCont5Btn = document.querySelector(
            'main.top .hero .contents .contents5 .btn'
        );
        this.heroCont6Btn = document.querySelector(
            'main.top .hero .contents .contents6 .btn'
        );
        this.heroCont7Btn = document.querySelector(
            'main.top .hero .contents .contents7 .btn'
        );
        this.heroCont8Btn = document.querySelector(
            'main.top .hero .contents .contents8 .btn'
        );
        this.heroCont9Btn = document.querySelector(
            'main.top .hero .contents .contents9 .btn'
        );

        this.sectionLine = document.querySelector('main.top .section_line');
        this.sectionLines = document.querySelectorAll(
            'main.top .section_line .line'
        );
        this.sections = document.querySelectorAll('main.top .section');
        this.releaseNotesContainer = document.querySelector(
            'main.top .section3 .documentation_update'
        );
        this.section4 = document.querySelector('main.top .section4');
        this.ourTeamContainer = document.querySelector(
            'main.top .section4 .items'
        );
        this.ourPartnerContainer = document.querySelector(
            'main.top .section5 .items'
        );
    }

    initEvents() {
        super.initEvents();

        this.mousewheelEvent =
            'onwheel' in document
                ? 'wheel'
                : 'onmousewheel' in document
                ? 'mousewheel'
                : 'DOMMouseScroll';

        this.bindWheelHandler = this.wheelHandler.bind(this);
        this.bindTouchEndHandler = this.touchEndHandler.bind(this);
        this.bindTouchMoveHandler = this.touchMoveHandler.bind(this);
        this.bindTouchStartHandler = this.touchStartHandler.bind(this);
        this.bindOpeningEndedHeroHandler =
            this.openingEndedHeroHandler.bind(this);
        this.bindChangedHeroHandler = this.changedHeroHandler.bind(this);
        this.bindClickHeroSkipHandler = this.clickHeroSkipHandler.bind(this);

        document.addEventListener(this.mousewheelEvent, this.bindWheelHandler, {
            passive: false,
        });
        document.addEventListener('touchstart', this.bindTouchStartHandler, {
            passive: false,
        });
        document.addEventListener('touchmove', this.bindTouchMoveHandler, {
            passive: false,
        });
        document.addEventListener('touchend', this.bindTouchEndHandler, {
            passive: false,
        });

        this.pack.hero.addEventListener(
            'openingEnded',
            this.bindOpeningEndedHeroHandler
        );
        this.pack.hero.addEventListener(
            'changedScene',
            this.bindChangedHeroHandler
        );

        this.heroSkip.addEventListener('click', this.bindClickHeroSkipHandler);
    }

    clickHeroSkipHandler(event) {
        this.pastHero = this.currentHero;
        this.currentHero = this.HERO_SCENE_LEN;
        this.pack.hero.skipScene();
        this.skipScene();
    }

    openingEndedHeroHandler(event) {
        this.enabledScroll = true;
    }

    changedHeroHandler(event) {
        this.enabledScroll = true;
        if (this.currentHero === this.HERO_SCENE_LEN) this.lockScroll = false;
    }

    wheelHandler(event) {
        if (this.pack.header.isMenuOpen) return;
        let delta = event.deltaY
            ? event.deltaY
            : event.wheelDelta
            ? event.wheelDelta
            : -event.detail;

        let currentTime = Date.now();
        let currentDelta = Math.abs(delta);
        let isCancel = false;

        if (currentDelta <= this.prevWheelDelta) {
            if (currentTime - this.prevWheelTime < 100) isCancel = true;
        }

        this.prevWheelTime = currentTime;
        this.prevWheelDelta = currentDelta;

        this.checkSceneChange(event, delta, isCancel);
    }

    touchStartHandler(event) {
        if (this.pack.header.isMenuOpen) return;
        let touches = event.changedTouches[0];
        this.touchStartPoint = touches.clientY;
    }

    touchMoveHandler(event) {
        if (this.pack.header.isMenuOpen) return;
        if (this.isWaitingBackHeroScene8) {
            event.preventDefault();
            return;
        }
        if (this.lockScroll) event.preventDefault();
    }

    touchEndHandler(event) {
        if (this.pack.header.isMenuOpen) return;
        let touches = event.changedTouches[0];
        let y = touches.clientY;
        let delta = y > this.touchStartPoint ? -1 : 1;
        if (y === this.touchStartPoint) return;
        this.checkSceneChange(event, delta);
    }

    checkSceneChange(event, delta, isCancel) {
        if (this.isWaitingBackHeroScene8) {
            event.preventDefault();
            return;
        }

        if (this.lockScroll) {
            if (isCancel) {
                event.preventDefault();
                return;
            }

            window.scrollTo(0, 0);
            event.preventDefault();

            if (!this.enabledScroll) return;
            let isPrev;
            if (delta > 0) {
                if (this.currentHero === this.HERO_SCENE_LEN) return;
                this.pastHero = this.currentHero;
                this.currentHero++;
            } else {
                if (this.currentHero === 1) return;
                this.pastHero = this.currentHero;
                this.currentHero--;
                isPrev = true;
            }

            this.enabledScroll = false;
            this.pack.hero.setScene(this.currentHero, isPrev);
            this.setScene(this.currentHero);
            this.changeNumerator(this.pack.addZero(String(this.currentHero)));
        } else {
            if (window.scrollY > 0) return;

            if (delta < 0) {
                this.isWaitingBackHeroScene8 = true;
                TweenMax.delayedCall(1, () => {
                    this.isWaitingBackHeroScene8 = false;
                    this.lockScroll = true;
                    this.pastHero = this.currentHero;
                    this.currentHero--;
                    this.enabledScroll = false;
                    this.pack.hero.setScene(this.currentHero, true);
                    this.setScene(this.currentHero);
                    this.changeNumerator(
                        this.pack.addZero(String(this.currentHero))
                    );
                });
            }
        }
    }

    start() {
        this.pack.hero.addHero(this.heroCanvasContainer);
        this.pack.hero.start();

        TweenMax.delayedCall(0.7, () => {
            this.heroContents[0].classList.add('show');
        });

        TweenMax.delayedCall(2.3, () => {
            this.heroContents[0].classList.add('show');
            this.heroScroll.classList.add('show');
            this.heroSkip.classList.add('show');
            this.heroCreateAccount.classList.add('show');
        });

        this.setValues();
        // this.setOurTeam();
        // this.loadOurTeamImages();
        this.setOurPartner();
        this.loadOurPartnerImages();
        this.pack.setReleaseNotes(this.releaseNotesContainer);
    }

    setValues() {
        let items = this.pack.apiJSONList[0].items;

        for (let i = 0, len = items.length; i < len; i++) {
            let item = items[i].fields;

            let title = this.pack.getTitle(item);
            let description = this.pack.getDescription(item);
            let url = item.url;
            let imgAccessAPIPath = this.pack.getContentfulAPIImgAccessPath(
                item.image.sys.id
            );

            this.ourTeamList[i] = {
                title: title,
                description: description,
                url: url,
            };
            this.ourTeamImgAccessAPIPathList[i] = imgAccessAPIPath;
        }

        items = this.pack.apiJSONList[1].items;
        for (let i = 0, len = items.length; i < len; i++) {
            let item = items[i].fields;
            let name = item.companyName;
            let description = this.pack.getDescription(item);
            let url = item.url;
            let imgAccessAPIPath = this.pack.getContentfulAPIImgAccessPath(
                item.logoImage.sys.id
            );

            this.ourPartnerList[i] = {
                name: name,
                description: description,
                url: url,
            };
            this.ourPartnerImgAccessAPIPathList[i] = imgAccessAPIPath;
        }
    }

    getOurTeamHTML(value) {
        /* pug src
            li.item.carousel_item
                a.white(href="" target="_blank")
                    img(src="/assets/img/_post/top/team_member.jpg" alt="")
                    .name About Dummy Name
                    p.txt Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget porttitor urna.
         */

        let title = value.title;
        let description = value.description;
        let url = value.url;

        return `
            <li class="item carousel_item">
                <a class="white" href="${url}" target="_blank">
                    <img src="/assets/img/top/default.jpg" alt="${title}">
                    <div class="name">${title}</div>
                    <p class="txt">${description}</p>
                </a>
            </li>
        
        `;
    }

    getOurPartnerHTML(value) {
        /*
            li.item
                a.white(href="" target="_blank")
                    div.img
                        img(src="/assets/img/_post/top/partner_logo.png" alt="")
                    p.txt Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut eget porttitor urna.
         */

        let name = value.name;
        let description = value.description;
        let url = value.url;

        return `
            <li class="item">
                <a class="white" href="${url}" target="_blank">
                    <div class="img">
                        <img src="" alt="${name}">
                    </div>
                    <p class="txt">${description}</p>
                </a>
            </li>
        `;
    }

    setOurTeam() {
        let src = '';
        for (let i = 0, len = this.ourTeamList.length; i < len; i++) {
            let item = this.ourTeamList[i];
            let html = this.getOurTeamHTML(item);
            src += html;
        }

        this.ourTeamContainer.innerHTML = src;

        this.carousel = new Carousel(this.pack);
        this.setCarousel();
    }

    setOurPartner() {
        let src = '';
        for (let i = 0, len = this.ourPartnerList.length; i < len; i++) {
            let item = this.ourPartnerList[i];
            let html = this.getOurPartnerHTML(item);
            src += html;
        }

        this.ourPartnerContainer.innerHTML = src;
    }

    loadOurTeamImages() {
        let urlList = this.ourTeamImgAccessAPIPathList;
        let promiseList = [];
        this.ourTeamImgPathList = [];

        urlList.forEach((url, i) => {
            promiseList.push(
                fetch(url)
                    .then((res) => {
                        return res.json();
                    })
                    .then((res) => {
                        this.ourTeamImgPathList[i] = res;
                    })
            );
        });

        Promise.all(promiseList).then(() => {
            // trace("Our team img path loaded", this.ourTeamImgPathList);
            this.setOurTeamImgs();
        });
    }

    loadOurPartnerImages() {
        let urlList = this.ourPartnerImgAccessAPIPathList;
        let promiseList = [];
        this.ourPartnerImgPathList = [];

        urlList.forEach((url, i) => {
            promiseList.push(
                fetch(url)
                    .then((res) => {
                        return res.json();
                    })
                    .then((res) => {
                        this.ourPartnerImgPathList[i] = res;
                    })
            );
        });

        Promise.all(promiseList).then(() => {
            // trace("Our partner img path loaded", this.ourPartnerImgPathList);
            this.setOurPartnerImgs();
        });
    }

    setOurTeamImgs() {
        let protocol = location.protocol;
        let imgs = document.querySelectorAll(
            'main.top .section4 .items .item img'
        );
        for (let i = 0, len = this.ourTeamImgPathList.length; i < len; i++) {
            let imgURL = protocol + this.ourTeamImgPathList[i].fields.file.url;
            if (imgURL) imgs[i].src = imgURL;
        }
    }

    setOurPartnerImgs() {
        let protocol = location.protocol;
        let imgs = document.querySelectorAll(
            'main.top .section5 .items .item img'
        );
        for (let i = 0, len = this.ourPartnerImgPathList.length; i < len; i++) {
            let imgURL =
                protocol + this.ourPartnerImgPathList[i].fields.file.url;
            imgs[i].src = imgURL;
        }
    }

    setScene(id) {
        this.heroContents[this.pastHero - 1].classList.remove('show');
        this.heroContents[this.currentHero - 1].classList.add('show');

        if (id === 1) {
            this.heroCreateAccount.classList.remove('hide');
            this.pack.header.showHeader();
        } else if (id === 2) {
            this.heroCreateAccount.classList.add('hide');
            this.pack.header.hideHeader();
        }
    }

    skipScene() {
        this.lockScroll = false;
        this.heroContents[this.pastHero - 1].classList.remove('show');
        this.heroContents[this.currentHero - 1].classList.add('show');
        this.heroCreateAccount.classList.add('hide');
        this.pack.header.hideHeader();
        this.changeNumerator(this.pack.addZero(String(this.currentHero)));
        this.pack.anchorScroll(this.sh * 3);
    }

    changeNumerator(value) {
        this.heroNumerator.innerText = value;
        this.heroNumerator.classList.add('blink');
        TweenMax.delayedCall(0.3, () => {
            this.heroNumerator.classList.remove('blink');
        });
    }

    scrollHandler() {
        this.checkSectionLine();
        this.checkSections();
    }

    checkSectionLine() {
        let top = this.sectionLine.getBoundingClientRect().top;

        if (top <= 0) {
            let percent = Math.abs(top / this.sh);

            if (percent > 1) percent = 1;

            for (let i = 0, len = this.sectionLines.length; i < len; i++) {
                let line = this.sectionLines[i];
                let y1 = -this.shh * percent;
                let x1 = -this.swh * percent;
                let y2 = this.shh * percent;
                let x2 = this.swh * percent;

                if (x2 >= this.swh - 2) x2 = this.swh - 2;

                if (i === 0)
                    line.style.transform = 'translate3d(0, ' + y1 + 'px, 0)';
                else if (i === 1)
                    line.style.transform = 'translate3d(' + x1 + 'px, 0, 0)';
                else if (i === 2)
                    line.style.transform = 'translate3d(0, ' + y2 + 'px, 0)';
                else line.style.transform = 'translate3d(' + x2 + 'px, 0, 0)';

                if (percent === 1) line.style.opacity = 0;
                else line.style.opacity = 1;
            }
        } else if (top < -this.sh) {
        } else {
            for (let i = 0, len = this.sectionLines.length; i < len; i++) {
                let line = this.sectionLines[i];
                line.style.transform = 'translate3d(0, 0, 0)';
            }
        }
    }

    checkSections() {
        for (let i = 0, len = this.sections.length; i < len; i++) {
            let item = this.sections[i];
            if (!item.classList.contains('show')) {
                let top = item.getBoundingClientRect().top;
                if (top < 100) item.classList.add('show');
            }
        }
    }

    enterframe() {}

    enterframeThinOut() {
        this.pack.hero.enterframeThinOut();
    }

    executeResize() {
        super.executeResize();

        this.heroScroll.style.top = this.sh + 'px';
        this.heroSkip.style.top = this.sh + 'px';
        this.heroCreateAccount.style.top = this.sh + 'px';

        this.heroCont4H3.style.top = this.sh + 'px';
        this.heroCont5H3.style.top = this.sh + 'px';
        this.heroCont6H3.style.top = this.sh + 'px';
        this.heroCont7H3.style.top = this.sh + 'px';
        this.heroCont8H3.style.top = this.sh + 'px';
        if (this.sw >= this.pack.BP)
            this.heroCont9H3.style.top = this.sh + 'px';
        else this.heroCont9H3.style.top = this.shh + 'px';

        this.heroCont4Txt.style.top = this.sh + 'px';
        this.heroCont5Txt.style.top = this.sh + 'px';
        this.heroCont6Txt.style.top = this.sh + 'px';
        this.heroCont7Txt.style.top = this.sh + 'px';
        this.heroCont8Txt.style.top = this.sh + 'px';
        if (this.sw >= this.pack.BP)
            this.heroCont9Txt.style.top = this.sh + 'px';
        else this.heroCont9Txt.style.top = this.shh + 'px';

        this.heroCont3Btn.style.top = this.sh + 'px';
        this.heroCont4Btn.style.top = this.sh + 'px';
        this.heroCont5Btn.style.top = this.sh + 'px';
        this.heroCont6Btn.style.top = this.sh + 'px';
        this.heroCont7Btn.style.top = this.sh + 'px';
        this.heroCont8Btn.style.top = this.sh + 'px';
        if (this.sw >= this.pack.BP)
            this.heroCont9Btn.style.top = this.sh + 'px';
        else this.heroCont9Btn.style.top = this.shh + 'px';

        this.heroCont8Chunk.style.top = this.sh + 'px';
    }
}
