import TemplateContents from './TemplateContents';

import Header from './Header';
import Footer from './Footer';
import Loading from './Loading';
import Transition from './Transition';
import Top from './Top';
import DevelopmentPlatform from './DevelopmentPlatform';
import OperationPlatform from './OperationPlatform';
import Contact from './Contact';
import ProductBrochure from './ProductBrochure';

import TopHero from './TopHero';

export default class extends TemplateContents {
    constructor(param) {
        super(param);

        this.pack.common = this;
    }

    init() {
        super.init();

        this.checkLang();

        this.header = this.pack.header = new Header(this.pack);
        this.footer = this.pack.footer = new Footer(this.pack);

        this.hero = this.pack.hero = new TopHero(this.pack);
        this.loadContentful();
        this.checkCookie();
    }

    checkLang() {
        //bot判別 botだったら以降の処理はスキップ
        let userAgent = window.navigator.userAgent.toLowerCase();
        if (userAgent.indexOf('bot') > -1) return;

        let pathname = location.pathname;
        let lang;
        lang = localStorage.getItem('lang');

        let href;
        if (this.pack.LANG === 'en') href = location.pathname;
        else href = (location.origin + location.pathname).split('/ja')[1];
        if (href === '') href = '/';

        if (lang === null) {
            //初訪問ユーザーの場合、ブラウザの言語判別をして飛ばす
            let language =
                (window.navigator.languages && window.navigator.languages[0]) ||
                window.navigator.language ||
                window.navigator.userLanguage ||
                window.navigator.browserLanguage;

            if (
                language === 'ja' ||
                language === 'ja-JP' ||
                language === 'ja-jp'
            ) {
                this.pack.setLocalStorage('jp');
                if (pathname !== '/ja' + href) location.href = '/ja' + href;
            } else {
                this.pack.setLocalStorage('en');
                if (pathname !== href) location.href = href;
            }
        } else {
            //LocalStorageに設定されている言語に飛ばす
            if (lang === 'jp') {
                if (pathname !== '/ja' + href) location.href = '/ja' + href;
            } else {
                if (pathname !== href) location.href = href;
            }
        }
    }

    addScrollTarget(target) {
        this.scrollTarget = target;
    }

    removeScrollTarget() {
        this.scrollTarget = undefined;
    }

    addEnterframeTarget(target) {
        this.enterframeTarget = target;
    }

    removeEnterframeTarget() {
        this.enterframeTarget = undefined;
    }

    addEvery90framesTarget(target) {
        this.every90framesTarget = target;
    }

    removeEvery90framesTarget() {
        this.every90framesTarget = undefined;
    }

    setVars() {
        super.setVars();
        this.enterframeID;
        this.enterframeIgnoreCnt = 0;

        this.pageYOffset = 0;
        this.pastPageYOffset = 0;
        this.scrollYOffset = 0;
        this.pastScrollYOffset = 0;

        //FPSを調整する変数　ここから
        //UPDATE_LOAD_COEFFはCP 負荷が爆発しないようにするための定数で、1フレームの間に使用可能な時間のうち、状態更新に割り当てられる時間の割合の最大値。この時間を超えて処理が行われると、フレームスキップが無効になって処理落ちが発生する。
        this.UPDATE_LOAD_COEFF = 0.5;
        this.FPS = 30;
        this.targetInterval = 1000 / this.FPS;
        this.prevTime = Date.now() - this.targetInterval;
        //FPSを調整する変数　ここまで
    }

    setDom() {
        super.setDom();
        this.scrollWrapper = document.querySelector('.scroll-wrapper');
        this.barbaContainer = document.querySelector('.barba-container');
        this.cookieAlert = document.querySelector('#cookie');
    }

    initEvents() {
        super.initEvents();

        this.resizeEvent = navigator.userAgent.match(/(iPhone|iPod|iPad)/)
            ? 'orientationchange'
            : 'resize';

        window.addEventListener(
            'DOMContentLoaded',
            this.DOMContentloadedHandler.bind(this)
        );
        window.addEventListener('load', this.loadedHandler.bind(this));

        window.addEventListener(
            this.resizeEvent,
            this.resizeHandler.bind(this),
            false
        );
        window.addEventListener('scroll', this.scrollHandler.bind(this), {
            passive: false,
        });

        this.resizeHandler();
        this.enterframe();
    }

    checkCookie() {
        let cookies = document.cookie; //全てのcookie取り出し
        let cookiesArray = cookies.split(';'); // ;で分割し配列に
        let isAgree;
        for (let c of cookiesArray) {
            let cArray = c.split('='); //=で分割して配列に
            if (cArray[0].indexOf('isCookieAgree') >= 0) {
                isAgree = true;
            }
        }

        if (isAgree) {
            // this.cookieAlert.classList.add("hide");
            this.cookieAlert.remove();
        } else {
            this.cookieAlert
                .querySelector('.btn')
                .addEventListener('click', (event) => {
                    document.cookie =
                        'isCookieAgree=true;path=/;expires=31 Dec 2037 00:00:00 GMT';
                    // this.cookieAlert.classList.add("hide");
                    this.cookieAlert.remove();
                });
        }
    }

    loadContentful() {
        let urlList = this.pack.CONTENTFUL_API_PATH_LIST;
        //Docsも一緒に読み込む
        if (this.pack.LANG === 'en') urlList.push(this.pack.DOCS_EN_API_PATH);
        else urlList.push(this.pack.DOCS_JA_API_PATH);

        let promiseList = [];
        this.jsonBaseList = this.pack.apiJSONList = [];

        urlList.forEach((url, i) => {
            promiseList.push(
                fetch(url)
                    .then((res) => {
                        return res.json();
                    })
                    .then((res) => {
                        this.jsonBaseList[i] = res;
                    })
            );
        });

        Promise.all(promiseList).then(() => {
            // trace("Contentful API loaded", this.jsonBaseList);
            this.loadContentfulPlatforms();
        });
    }

    loadContentfulPlatforms() {
        this.pack.apiJSONDevelopPlatformList = [];
        this.pack.apiJSONOperationPlatformList = [];
        let promiseList = [];

        let urlList1 = [];
        let urlList2 = [];
        let developPlatformItems = this.jsonBaseList[2].items;
        let operationPlatformItems = this.jsonBaseList[4].items;

        for (let i = 0, len = developPlatformItems.length; i < len; i++) {
            let item = developPlatformItems[i];
            let id = item.sys.id;
            urlList1.push(
                this.pack.getContentfulAPIPlatformSingleItemPath(0, id)
            );
        }

        for (let i = 0, len = operationPlatformItems.length; i < len; i++) {
            let item = operationPlatformItems[i];
            let id = item.sys.id;
            urlList2.push(
                this.pack.getContentfulAPIPlatformSingleItemPath(1, id)
            );
        }

        urlList1.forEach((url, i) => {
            promiseList.push(
                fetch(url)
                    .then((res) => {
                        return res.json();
                    })
                    .then((res) => {
                        this.pack.apiJSONDevelopPlatformList[i] = res;
                    })
            );
        });

        urlList2.forEach((url, i) => {
            promiseList.push(
                fetch(url)
                    .then((res) => {
                        return res.json();
                    })
                    .then((res) => {
                        this.pack.apiJSONOperationPlatformList[i] = res;
                    })
            );
        });

        Promise.all(promiseList).then(() => {
            // trace("Contentful API loaded", this.pack.apiJSONDevelopPlatformList, this.pack.apiJSONOperationPlatformList);
            this.loadedContentful = true;
        });
    }

    DOMContentloadedHandler(event) {
        this.bindLoadingCompleteHandler =
            this.loadingCompleteHandler.bind(this);
        this.loading = this.pack.loading = new Loading(this.pack);
        this.loading.addEventListener(
            'complete',
            this.bindLoadingCompleteHandler
        );
        this.transition = this.pack.transition = new Transition(this.pack);
        // this.initContainer();
        document.body.style.opacity = 1;
    }

    loadedHandler(event) {
        window.scrollTo(0, 0);
    }

    loadingCompleteHandler(event) {
        this.loading.removeEventListener(
            'complete',
            this.bindLoadingCompleteHandler
        );
        this.loading = null;
        this.initContents();
        TweenMax.delayedCall(1.5, () => {
            this.header.showHeaderNav();
        });
    }

    initContents() {
        window.scrollTo(0, 0);

        let namespace = this.barbaContainer.dataset.namespace;
        this.pack.current = namespace;

        if (namespace === 'top') {
            this.pack.top = new Top(this.pack);
            this.pack.top.start();
        } else if (namespace === 'development_platform') {
            this.pack.development_platform = new DevelopmentPlatform(this.pack);
            this.pack.development_platform.start();
        } else if (namespace === 'operation_platform') {
            this.pack.operation_platform = new OperationPlatform(this.pack);
            this.pack.operation_platform.start();
        } else if (namespace === 'contact') {
            this.pack.contact = new Contact(this.pack);
            this.pack.contact.start();
        } else if (namespace === 'product_brochure') {
            this.pack.product_brochure = new ProductBrochure(this.pack);
            this.pack.product_brochure.start();
        }

        this.pack.header.setAnchors();
        this.setCurrent();
    }

    setCurrent() {
        this.pack.header.setCurrent();
    }

    start() {}

    startTransitionIn(callback) {
        this.transition.transitIn(callback);
    }

    startTransitionOut() {
        this.transition.transitOut();
    }

    resetPageYOffset() {
        this.pageYOffset = 0;
        this.scrollYOffset = 0;
    }

    scrollHandler() {
        this.pack.pageYOffset = window.pageYOffset;
        if (this.scrollTarget) this.scrollTarget.scrollHandler();
    }

    enterframe() {
        this.enterframeID = window.requestAnimationFrame(
            this.enterframe.bind(this)
        );

        if (this.enterframeTarget && this.enterframeTarget.enterframe)
            this.enterframeTarget.enterframe();

        let currentTime = Date.now();
        let updated = false;
        while (currentTime - this.prevTime > this.targetInterval) {
            updated = true;
            this.prevTime += this.targetInterval;
            const now = Date.now();
            const updateTime = now - currentTime;
            if (updateTime > this.targetInterval * this.UPDATE_LOAD_COEFF) {
                // overloaded
                if (this.prevTime < now - this.targetInterval) {
                    // do not accumulate too much
                    this.prevTime = now - this.targetInterval;
                }
                break;
            }
        }

        if (updated) {
            //30fpsで処理
            this.enterframeThinOut();
            if (this.enterframeTarget)
                this.enterframeTarget.enterframeThinOut();
            if (this.loading) this.loading.enterframeThinOut();
            if (this.transition) this.transition.enterframeThinOut();
        }

        /*
        if(this.enterframeIgnoreCnt%2 === 0){

        }
*/

        if (this.enterframeIgnoreCnt % 90 === 0) {
            //間引き処理(1 execution per 90 frames)
            this.resizeHandler();
            if (this.every90framesTarget)
                this.every90framesTarget.enterEvery90Frames();
        }

        //前回のスクロール位置（全ての処理が終わってから代入）
        this.pastPageYOffset = this.pack.pastPageYOffset =
            this.pack.pageYOffset;

        this.enterframeIgnoreCnt++;
    }

    enterframeThinOut() {}

    executeResize() {
        super.executeResize();
    }
}
