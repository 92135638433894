import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init(true);
        this.resizeEvent = 'resize';

        this.initEvents();
        this.resizeHandler();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
    }

    setDom(){
        super.setDom();
        this.canvasContainer = document.querySelector('#transition');
    }

    initEvents(){
        super.initEvents();
    }

    transitIn(callback, scope){
        let dr = .8;
        this.enabled = true;
        this.canvasContainer.classList.add("show");
        TweenMax.to(this.canvasContainer, dr, {opacity:1, ease:Quart.easeOut, onComplete:()=>{
            if(scope) callback.call(scope);
            else callback();
        }});
    }

    transitOut(){
        let dr = .8;
        TweenMax.to(this.canvasContainer, dr, {opacity:0, ease:Quart.easeIn, onComplete:()=>{
            this.enabled = false;
            this.canvasContainer.classList.remove("show");
        }});
    }

    update(){

    }

    start(){

    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){
        if(this.enabled) this.update();
    }

    executeResize() {
        super.executeResize();

        this.canvasContainer.style.width = this.sw + 'px';
        this.canvasContainer.style.height = this.sh + 'px';
    }
}
