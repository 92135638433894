import TemplateContents from './TemplateContents';

import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MeshoptDecoder } from 'three/examples/jsm/libs/meshopt_decoder.module.js';
import { LinearFilter } from 'three/src/constants'

// import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
// import Stats from 'three/examples/jsm/libs/stats.module';
// import GUI from 'lil-gui';

//Lights
const ambientIntensity = 0.7,
    ambientColor = 0xEBF5FF,
    directionalIntensity = 0.3,
    directionalColor = 0xFFFFFF,
    directionalLightX = 0.5,
    directionalLightY = 0,
    directionalLightZ = 0.866,
    roadColor = 0x060e39,
    groundColor = 0x101741;

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init(true);
        this.resizeEvent = 'resize';

        this.initEvents();

        // this.devicePixelRatio = window.devicePixelRatio || 1;
        // this.devicePixelRatio = Math.min(2, this.devicePixelRatio);

        this.initShader();
        this.initWebGL();
        this.load();

        if(this.enabledStats) this.setStats();
        if(this.enabledGUI) this.setGUI();
    }

    reset(){
        // super.reset();
        // this.setVars();
        this.initEvents();
    }

    destruct(){
        super.destruct();

        this.enabledUpdate = true;

        this.canvas.removeEventListener(this.pack.hasTouch ? 'touchmove' : 'mousemove', this.bindPointermoveHandler);

        this.camera.position.x = this.defCameraPosition.x;
        this.camera.position.y = this.defCameraPosition.y;
        this.camera.position.z = this.defCameraPosition.z;

        this.currentTargetPosition = new THREE.Vector3(0, 0, 0);
        this.currentLookatTargetPosition = new THREE.Vector3(0, 0, 0);

        this.currentScene = 1;

        this.currentWheelSpeed = this.wheelSpeed;

        this.showGeom(this.human);
        this.container.remove(this.sensor);
        this.container.remove(this.sensor07);
        this.container.remove(this.wind);
        this.hideGeom(this.pointCloud);

        TweenMax.to(this.canvas, 1, {opacity:1, ease:Quad.easeOut});
        this.changeToDefColor();
        TweenMax.to(this.directionalLight, 1, {intensity:directionalIntensity, ease:Quad.easeOut});

        for( let i = 0, len = this.animeList.length; i < len; i++ ) {
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "mainRout"){
                action.paused = false;
                mixer.setTime(0);
                action.reset().play();
                action.paused = true;
            }else if(anime.name === "dashedRout"){
                action.paused = false;
                mixer.setTime(0);
                action.reset().play();
                action.paused = true;
            }
        }
    }

    getPercent(){
        return this.loadedCnt / this.loadLength * 100;
    }

    setVars(){
        super.setVars();

        this.enabledUpdate = true;
        this.enabledOrbitControls = false;
        this.enabledStats = false;
        this.enabledGUI = false;

        this.enabledChangeScene = false;

        this.loadLength = 14;
        this.loadedCnt = 0;

        this.GLB_ASSET_PATH = '/assets/glb/';
        this.IMG_ASSET_PATH = '/assets/img/top/3d/';

        this.POINT_IMG_PATH = this.IMG_ASSET_PATH + 'point/point.png';
        this.ENV_IMG_PATH = this.IMG_ASSET_PATH + 'env/env_white.jpg';

        let extension = (this.pack.ua.isSafari && !this.pack.ua.isSmartPhone) ? 'png' : 'webp';

        this.BAK_BUILDING_IMG_PATH = this.IMG_ASSET_PATH + 'bake/tex_building_col.' + extension;
        this.BAK_GROUND_IMG_PATH = this.IMG_ASSET_PATH + 'bake/tex_ground_col.' + extension;
        this.BAK_ROAD_IMG_PATH = this.IMG_ASSET_PATH + 'bake/tex_road_col.' + extension;
        this.BAK_SHADOW_IMG_PATH = this.IMG_ASSET_PATH + 'bake/bake_shadow_alpha_cc.' + extension;

        this.currentTargetPosition = new THREE.Vector3(0, 0, 0);
        this.currentLookatTargetPosition = new THREE.Vector3(0, 0, 0);

        this.currentCarPosVector;
        this.currentScene = 1;

        this.defRoadColor;
        this.defGroundColor;

        this.content;
        this.animeList = [];
        this.clock = new THREE.Clock();

        this.wheelSpeed = 15;
        this.currentWheelSpeed = this.wheelSpeed;
        this.wheelsDeg = 0;

        this.CAR_WIRE_CLONE_LEN = 10;
        this.carWireClones = [];

        this.mouseStage = new THREE.Vector2();

        this.SOLID_ROAD_COLOR = 0xA6A8AB;
        this.SOLID_GROUND_COLOR = 0xBABABA;
        this.POINTS_ROAD_COLOR = 0x001142;
        this.POINTS_GROUND_COLOR = 0x0A2064;

        this.SCENE4_7_CAMERA_INFO = {
            targetX:-5,
            targetY:48,
            targetZ:58,
            lookAtTargetX:3,
            lookAtTargetY:0,
            lookAtTargetZ:-2.3
        };

        this.carShadowY = .05;
    }

    setDom(){
        super.setDom();

        this.canvas = document.createElement("canvas");
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.canvas.width = this.width;
        this.canvas.height = this.height;
    }

    addHero(container){
        container.appendChild(this.canvas);
    }

    removeHero(container){
        this.canvas.remove();
    }

    initEvents(){
        super.initEvents();

        this.bindPointermoveHandler = this.pointerMoveHandler.bind(this);
        //マウスオーバーとドラッグ処理
        this.canvas.addEventListener(this.pack.hasTouch ? 'touchmove' : 'mousemove', this.bindPointermoveHandler,{passive : false});
    }

    //for debug
    setGUI(){
        let sc = this;
        const gui = new GUI();
        const PROPS = {
            'ambientLight' : ambientColor,
            'ambientIntensity' : ambientIntensity,
            'directionalLight' : directionalColor,
            'directionalIntensity' : directionalIntensity,
            'directionalX' : directionalLightX,
            'directionalY' : directionalLightY,
            'directionalZ' : directionalLightY,
            'cameraPosX': 0,
            'cameraPosY': 0,
            'cameraPosZ': 0,
            'cameraRotX': 0,
            'cameraRotY': 0,
            'cameraRotZ': 0,
            'roadColor' : roadColor,
            'groundColor' : groundColor,
        };

        gui.width = 350;
        gui.left = 0;
        gui.domElement.style.zIndex = 2000;
        gui.close();

        gui.addColor(PROPS, 'ambientLight').onChange(function(){
            sc.ambientLight.color.set(PROPS.ambientLight);
        });

        gui.add(PROPS, 'ambientIntensity', 0, 1).onChange(function(){
            sc.ambientLight.intensity = PROPS.ambientIntensity;
        });

        gui.addColor(PROPS, 'directionalLight').onChange(function(){
            sc.directionalLight.color.set(PROPS.directionalLight);
        });

        gui.add(PROPS, 'directionalIntensity', 0, 1).onChange(function(){
            sc.directionalLight.intensity = PROPS.directionalIntensity;
        });

        gui.add(PROPS, 'directionalX', -Math.PI, Math.PI).onChange(() => {
            this.directionalLight.position.x = PROPS.directionalX;
        });

        gui.add(PROPS, 'directionalY', -Math.PI, Math.PI).onChange(() => {
            this.directionalLight.position.y = PROPS.directionalY;
        });

        gui.add(PROPS, 'directionalZ', -Math.PI, Math.PI).onChange(() => {
            this.directionalLight.position.z = PROPS.directionalZ;
        });

        gui.addColor(PROPS, 'roadColor').onChange(()=>{
            this.road.material.color.set(PROPS.roadColor);
        });

        gui.addColor(PROPS, 'groundColor').onChange(()=>{
            this.ground.material.color.set(PROPS.groundColor);
        });
    }

    setStats(){
        this.stats = Stats()
        document.body.appendChild(this.stats.dom)
    }

    pointerMoveHandler(event){
        let x, y;
        let w = this.sw;
        let h = this.sh;

        if(event.type.indexOf('touch') == 0) {
            let touches = event.changedTouches[0];
            x = touches.clientX;
            y = touches.clientY;
        }else{
            x = event.clientX;
            y = event.clientY;
        }

        this.mouseStage.x = x - (w / 2);
        this.mouseStage.y = -( y ) + (h / 2);
    }

    initShader() {
        this.vertexShaderSrc = `
        
        `;

        this.fragmentShaderSrc = `
        
        `;
    }

    initWebGL() {
        this.renderer = new THREE.WebGLRenderer({
            canvas: this.canvas,
            alpha : true,
            antialias : true
        });
        // this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setPixelRatio(1);
        this.renderer.setSize(this.width, this.height);
        this.renderer.autoClear = false;


        this.scene = new THREE.Scene();
        this.scene.background = new THREE.Color( 0xE8FAFE );


        //Camera
        this.fieldOfView = 45;
        this.defCameraPosition = {};
        this.defCameraPosition.x = 192.3904571533203;
        this.defCameraPosition.y = 300;
        this.defCameraPosition.z = -135.74269104003906;
        this.camera = new THREE.PerspectiveCamera( this.fieldOfView, this.width / this.height, 0.01, 1000 );
        this.camera.position.x = this.defCameraPosition.x;
        this.camera.position.y = this.defCameraPosition.y;
        this.camera.position.z = this.defCameraPosition.z;

        this.ambientLight  = new THREE.AmbientLight(ambientColor, ambientIntensity);
        this.scene.add( this.ambientLight );

        this.directionalLight = new THREE.DirectionalLight(directionalColor, directionalIntensity);
        this.directionalLight.position.set(directionalLightX, directionalLightY, directionalLightZ).normalize();
        this.scene.add(this.directionalLight);


        //Controls
        if(this.enabledOrbitControls){
            this.controls = new OrbitControls(this.camera, this.renderer.domElement);
            // this.controls.enableDamping = true
        }
    }

    load(){
        this.container = new THREE.Object3D();
        this.scene.add(this.container);

        this.loadTexture(this.ENV_IMG_PATH, "texture_env")
            .then(this.loadTexture.bind(this, this.POINT_IMG_PATH, "texture_point"))
            .then(this.loadTexture.bind(this, this.BAK_BUILDING_IMG_PATH, "texture_bake_building"))
            .then(this.loadTexture.bind(this, this.BAK_GROUND_IMG_PATH, "texture_bake_ground"))
            .then(this.loadTexture.bind(this, this.BAK_ROAD_IMG_PATH, "texture_bake_road"))
            .then(this.loadTexture.bind(this, this.BAK_SHADOW_IMG_PATH, "texture_bake_shadow"))
            .then(this.loadGeom.bind(this))
            .then(this.loadCar.bind(this))
            .then(this.loadCarWire.bind(this))
            .then(this.loadWind.bind(this))
            .then(this.loadSensor.bind(this))
            .then(this.loadSensor07.bind(this))
            .then(this.loadMainRout.bind(this))
            .then(this.loadDashedRout.bind(this))
            .then(() => {
                this.initMesh();
                let roadColor = this.road.material.color;
                let groundColor = this.ground.material.color;
                this.defRoadColor = {r:roadColor.r,g:roadColor.g,b:roadColor.b};
                this.defGroundColor = {r:groundColor.r,g:groundColor.g,b:groundColor.b};

                TweenMax.delayedCall(.5, ()=>{
                    this.loaded = true;
                    // trace("HERO 3D loaded");
                });
            });
    }


    loadTexture(path, name){
        let textureLoader = new THREE.TextureLoader();
        return new Promise((resolve) =>{
            textureLoader.load(path, (texture) => {
                texture.mapping = THREE.UVMapping;
                texture.flipY = false;
                texture.minFilter = LinearFilter;
                texture.magFilter = LinearFilter;
                this[name] = texture;
                this.loadedCnt++;

                return resolve();
            });
        });

    }

    loadGeom(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/geom_meshopt.glb',
                (gltf) => {
                    const scene = gltf.scene || gltf.scenes[0];
                    const clips = gltf.animations || [];

                    // trace("geom.glb", gltf);
                    this.setGeom(scene, clips);
                    this.loadedCnt++;
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    showGeom(geom, opacity){
        const material = geom.material;
        // material.opacity = opacity || 1;
        material.needsUpdate = true;

        let o = opacity || 1;

        TweenMax.killTweensOf(material);
        TweenMax.to(material, 1, {opacity:o, ease:Quart.easeOut});
    }

    hideGeom(geom){
        const material = geom.material;
        material.transparent = true;
        material.alphaToCoverage = true;
        // material.opacity = 0;
        material.needsUpdate = true;
        TweenMax.killTweensOf(material);
        TweenMax.to(material, 1, {opacity:0, ease:Quart.easeOut});
    }

    setPointCloud(node){
        let geo, targetChild;
        let child = node.getObjectByName("GEOM_PointCloud");
        geo = child.geometry;
        targetChild = child;

/*
        node.traverse((child) => {
            if (child instanceof THREE.Mesh === true || child instanceof THREE.SkinnedMesh === true) {
                geo = child.geometry;
                targetChild = child;
            }
        });
*/

        this.pointCloud = new THREE.Points(geo, new THREE.PointsMaterial({
            /// 点のサイズ
            // size: 1.4,
            size: .7,
            // size: 5,
            map:this.texture_point,
            /// 点の色
            color: 0xFFFFFF,
            transparent:true,
            alphaToCoverage:true,
            /// サイズ減退の有無
            sizeAttenuation: true
            // sizeAttenuation: false
        }));

        //TODO  原因究明したらあとで消す　ここから
        this.pointCloud.scale.x = targetChild.scale.x;
        this.pointCloud.scale.y = targetChild.scale.y;
        this.pointCloud.scale.z = targetChild.scale.z;

        this.pointCloud.position.x = targetChild.position.x;
        this.pointCloud.position.y = targetChild.position.y;
        this.pointCloud.position.z = targetChild.position.z;
        //TODO  原因究明したらあとで消す　ここまで

        // trace("targetChild", targetChild);
        // trace("targetChild", targetChild.scale.x, targetChild.scale.y, targetChild.scale.z);
        // trace("pointCloud", this.pointCloud.scale.x, this.pointCloud.scale.y, this.pointCloud.scale.z);
        // trace("targetChild", targetChild.position.x, targetChild.position.y, targetChild.position.z);
        // trace("pointCloud", this.pointCloud.position.x, this.pointCloud.position.y, this.pointCloud.position.z);
    }

    setGeom(object, clips){
        this.container.add(object);
        this.content = object;
        let node;

        //car body
        this.car = this.content.getObjectByName("GEOM_Body");
        this.carTyres = [];
        this.car.traverse((child) => {
            if(child.isMesh) child.material.transparent = true;
            if(child.name.indexOf("GEOM_Tyre") > -1) this.carTyres.push(child);
        });
        this.carCameraTarget = new THREE.Object3D();
        this.car.add(this.carCameraTarget);
        this.cameraLookAtTarget = new THREE.Object3D();
        this.car.add(this.cameraLookAtTarget);

        //carWire
        this.carWire = this.content.getObjectByName("GEOM_CarWire");
        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let clone = this.carWire.clone();
            this.container.add(clone);
            this.carWireClones.push(clone);
        }

        //building
        this.building = this.content.getObjectByName("GEOM_Building");
        this.building.material.lightMap = this.texture_bake_building;
        this.building.material.needsUpdate = true;
        this.building.position.y -= 1;

        //pointCloud
        node = this.content.getObjectByName("GEOM_PointCloud");
        this.hideGeom(node);
        this.setPointCloud(node);
        this.container.add(this.pointCloud);
        node.position.y -= 1;
        this.hideGeom(this.pointCloud);

        //ground
        this.ground = this.content.getObjectByName("GEOM_Ground");
        this.ground.material.color = new THREE.Color(this.SOLID_GROUND_COLOR);
        this.ground.material.lightMap = this.texture_bake_ground;
        this.ground.material.needsUpdate = true;
        this.ground.position.y -= 1;

        //road
        this.road = this.content.getObjectByName("GEOM_Road");
        this.road.material.color = new THREE.Color(this.SOLID_ROAD_COLOR);
        this.road.material.lightMap = this.texture_bake_road;
        this.road.material.needsUpdate = true;

        //human
        this.human = this.content.getObjectByName("GEOM_Human");
        this.human.position.x = 175;
        this.human.position.z = -130;
        this.human.rotation.y = this.pack.d2r(180);

        //shadow
        this.carShadow = this.content.getObjectByName("GEOM_Shadow");
        node.material.transparent = true;
        node.material.alphaToCoverage = true;
        node.material.map = this.texture_bake_shadow;
        node.material.needsUpdate = true;
        node.material.opacity = 1;
    }

    loadCar(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/s07_car_anim_0615_meshopt_mid.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "car");

                    this.cameraTarget = gltf.scene.getObjectByName("SOCKET_CarAnim07");

                    // this.container.add(gltf.scene);
                    this.loadedCnt++;
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadCarWire(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/s07_carwire_anim_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "carWire");
                    this.carWireTarget = gltf.scene.getObjectByName("other_followpath_filtered");

                    for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
                        let sceneClone = gltf.scene.clone();
                        let id = i + 1;
                        this.setAnimationClip(sceneClone, gltf.animations, "carWireClone" + id, false, (12 + Math.random() * .5) * id);
                        this["carWireTarget" + id] = sceneClone.getObjectByName("other_followpath_filtered");
                    }

                    // this.container.add(gltf.scene);
                    this.loadedCnt++;
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadSensor(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/s03_sensor_anim_wgeo_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "sensor");

                    this.sensorMeshMaterials = [];

                    for( let i = 1, len = 4; i <= len; i++ ){
                        let child = gltf.scene.getObjectByName("GEOM_SensorAnim" + i);
                        const material = child.material;
                        material.transparent = true;
                        material.alphaToCoverage = true;
                        material.opacity = 0.7;
                        material.needsUpdate = true;
                        this.sensorMeshMaterials.push(material);
                    }

                    this.sensor = gltf.scene;
                    // this.container.add(this.sensor);
                    this.loadedCnt++;
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadSensor07(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/s07_sensor_anim_wgeo_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "sensor07");

                    this.sensor07MeshMaterials = [];
                    let child = gltf.scene.getObjectByName("GEOM_SensorFlat");
                    const material = child.material;
                    material.transparent = true;
                    material.alphaToCoverage = true;
                    material.opacity = 0.3;
                    material.needsUpdate = true;
                    this.sensor07MeshMaterials.push(material);

                    this.sensor07 = gltf.scene;

                    // this.container.add(this.sensor07);
                    this.loadedCnt++;
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadMainRout(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/s05_mainroute_anim_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "mainRout", true);

                    // gltf.scene.position.y = .2;
                    this.container.add(gltf.scene);
                    this.loadedCnt++;
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadDashedRout(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/s05_dashroute_anim_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "dashedRout", true);

                    // gltf.scene.position.y = .2;
                    this.container.add(gltf.scene);
                    this.loadedCnt++;
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    loadWind(){
        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        return new Promise((resolve) =>{
            loader.load(
                '/assets/glb/meshopt/s01_wind_anim_wgeo_meshopt.glb',
                (gltf) => {
                    this.setAnimationClip(gltf.scene, gltf.animations, "wind");

                    for( let i = 1, len = 3; i <= len; i++ ){
                        let child = gltf.scene.getObjectByName("GEOM_FxWind" + i);
                        const material = child.material;
                        material.transparent = true;
                        material.alphaToCoverage = true;
                        material.opacity = 0.3;
                        material.needsUpdate = true;
                    }

                    this.wind = gltf.scene;
                    // this.container.add(this.wind);
                    this.loadedCnt++;
                    return resolve();
                },
                (xhr) => {
                    // console.log((xhr.loaded / xhr.total) * 100 + '% loaded')
                },
                (error) => {
                    console.log(error)
                }
            );
        });
    }

    setAnimationClip(scene, animations, name, isOnce, offsetTime){
        let mixer = new THREE.AnimationMixer( scene );
        let anime = {};
        anime.mixer = mixer;
        anime.name = name;

        animations.forEach((clip, clipIndex) => {
            let action = mixer.clipAction(clip);
            if(isOnce) {
                //1回のみ再生
                action.setLoop(THREE.LoopOnce);
                action.clampWhenFinished = true;
            }

            anime.duration = clip.duration;
            anime.action = action;
        });

        if(!offsetTime) offsetTime = 0;
        anime.offsetTime = offsetTime;  //開始点指定
        this.animeList.push(anime);
    }

    initMesh(){
        // for debug
        // let geometry = new THREE.PlaneGeometry( 3, 3, 4 );
        // var material = new THREE.MeshBasicMaterial( {color: 0xff0000, opacity:.5, side: THREE.DoubleSide} );
        // this.plane = new THREE.Mesh( geometry, material );
        // this.scene.add( this.plane );

        let options = {
            generateMipmaps: true,
            minFilter: THREE.LinearMipmapLinearFilter,
            magFilter: THREE.LinearFilter
        };

        //for Environment
        const renderTargetCube = new THREE.WebGLCubeRenderTarget( 1024, options ).fromEquirectangularTexture( this.renderer, this.texture_env );

        //add EnvironmentMap
        this.updateEnvironment(renderTargetCube.texture);
    }

    start(){
        window.scrollTo(0, 0);
        this.initPosVectorForScene3();
        this.startScene1();

        let delay = 2.5;
        TweenMax.delayedCall(delay, () => {
            this.dispatchEvent("openingEnded");
        });
    }

    initPosVectorForScene3(){
        this.cameraLookAtTargetPosVectorForScene3 = new THREE.Vector3(68.16640483005366, 2.1, -114.95982765389802 );
        this.cameraTargetPosVectorForScene3 = new THREE.Vector3(69.49970627529714, 2.999999999999998, -105.02442090550005 );
    }

    transitPosVectorForScene3(){
        if(this.sw >= this.pack.BP){
            TweenMax.to(this.cameraLookAtTargetPosVectorForScene3, .5, {x:177.9500803250232, y:2.1000000000000014, z:-140.44301588229436, ease:Quart.easeInOut});
        }else{
            TweenMax.to(this.cameraLookAtTargetPosVectorForScene3, .5, {x:188, y:7.1, z:-140, ease:Quart.easeInOut});
        }

        TweenMax.to(this.cameraTargetPosVectorForScene3, .5, {x:200, y:9.5, z:-148, ease:Quart.easeInOut});
    }

    setScene(id, isPrev){
        let delay;
        this.currentScene = id;

        if(this.currentScene === 1){
            this.startScene1(isPrev);
            delay = 1.8;
        }else if(this.currentScene === 2){
            this.startScene2(isPrev);
            delay = 1.8;
        }else if(this.currentScene === 3){
            this.startScene3(isPrev);
            delay = 2.0;
        }else if(this.currentScene === 4){
            this.startScene4(isPrev);
            delay = 2.0;
        }else if(this.currentScene === 5){
            this.startScene5(isPrev);
            delay = 2.0;
        }else if(this.currentScene === 6){
            this.startScene6(isPrev);
            delay = 2.0;
        }else if(this.currentScene === 7){
            this.startScene7(isPrev);
            delay = 2.0;
        }else if(this.currentScene === 8){
            this.startScene8(isPrev);
            delay = 2.5;
        }else if(this.currentScene === 9){
            this.startScene9(isPrev);
            delay = 2.0;
        }

        TweenMax.delayedCall(delay, () => {
            this.dispatchEvent("changedScene");
        });
    }

    skipScene(){
        TweenMax.delayedCall(.5, ()=>{
            this.transitPosVectorForScene3();
            this.container.remove(this.sensor);
            this.container.remove(this.wind);
            this.hideGeom(this.pointCloud);
            this.showGeom(this.ground);
            this.showGeom(this.building);
            this.changeToDefColor();
            this.startScene9();
        });
    }

    startScene1(isPrev){

        TweenMax.to(this.directionalLight, 1, {intensity:directionalIntensity, ease:Quad.easeOut});

        if(this.sw >= this.pack.BP){
            this.carCameraTarget.position.x = 40;
            this.carCameraTarget.position.y = 35;
            this.carCameraTarget.position.z = 40;
            this.cameraLookAtTarget.position.x = 20;
            if(this.sw < 1280) this.cameraLookAtTarget.position.x = 4;
            this.cameraLookAtTarget.position.y = 0;
            this.cameraLookAtTarget.position.z = -38;
        }else{
            this.carCameraTarget.position.x = 30;
            this.carCameraTarget.position.y = 40;
            this.carCameraTarget.position.z = 55;
            this.cameraLookAtTarget.position.x = -26;
            this.cameraLookAtTarget.position.y = 0;
            this.cameraLookAtTarget.position.z = -24;
        }

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;
            if(anime.name === "car" || anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0) {
                action.paused = false;
                action.reset().play();
                mixer.setTime(anime.offsetTime);
            }
        }

        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.showGeom(item);
        }
        this.showGeom(this.carWire);

        this.showGeom(this.building);
        this.showGeom(this.road);
        this.showGeom(this.ground);
        this.container.remove(this.wind);
    }

    startScene2(isPrev){
        // this.cameraLookAtTarget.position.x = this.currentCarPosVector.x - 10;
        // this.cameraLookAtTarget.position.y = this.currentCarPosVector.y;
        // this.cameraLookAtTarget.position.z = this.currentCarPosVector.z - 10;

        TweenMax.to(this.directionalLight, 1, {intensity:1, ease:Quad.easeOut});

        this.carCameraTarget.position.x = -8.5;
        this.carCameraTarget.position.y = 2;
        this.carCameraTarget.position.z = -8.5;

        if(this.sw >= this.pack.BP){
            TweenMax.to(this.cameraLookAtTarget.position, .9, {x:1, y:2.6, z:-3.3, ease:Quart.easeOut});
        }else{
            TweenMax.to(this.cameraLookAtTarget.position, .9, {x:0, y:2.6, z:-1.3, ease:Quart.easeOut});
        }

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;
            action.paused = true;
            if(anime.name === "car"){
                action.paused = false;
                mixer.setTime(14);
                action.play();
                action.paused = true;
            }else if(anime.name === "wind"){
                action.paused = false;
                action.reset().play();
            }
        }

        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.hideGeom(item);
        }
        this.hideGeom(this.carWire);
        this.hideGeom(this.building);
        this.hideGeom(this.ground);
        this.container.remove(this.sensor);
        this.container.add(this.wind);

        TweenMax.killTweensOf(this);
        this.currentWheelSpeed = this.wheelSpeed;
    }

    startScene3(isPrev){

        TweenMax.to(this.directionalLight, 1, {intensity:directionalIntensity, ease:Quad.easeOut});

        if(!isPrev){
            //TODO 動的に値を代入できるようにしたい
            /*
                scene2のthis.cameraLookAtTargetのワールド座標(以下で求めた値)
                let cameraLookAtTargetPosVector = new THREE.Vector3();
                cameraLookAtTargetPosVector = this.cameraLookAtTarget.getWorldPosition(cameraLookAtTargetPosVector);
                x: 68.16640483005366
                y: 2.1
                z: -114.95982765389802

                scene3のthis.cameraLookAtTargetのワールド座標(以下で求めた値)
                let cameraLookAtTargetPosVector = new THREE.Vector3();
                cameraLookAtTargetPosVector = this.cameraLookAtTarget.getWorldPosition(cameraLookAtTargetPosVector);
                x: 66.95807593049197
                y: 2.1000000000000014
                z: -120.32638532809514

                x: 177.9500803250232
                y: 2.1000000000000014
                z: -140.44301588229436

                scene2の車の位置
                x: 69.49970627529714
                y: 2.999999999999998
                z: -105.02442090550005
             */

            this.initPosVectorForScene3();
            this.transitPosVectorForScene3();
        }


        this.cameraLookAtTarget.position.x = 5.5;
        this.cameraLookAtTarget.position.y = 2.1;
        this.cameraLookAtTarget.position.z = -3.4;

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;

            if(anime.name === "car"){
                action.paused = false;
                action.reset().play();
                action.paused = true;
            }
        }

        this.container.remove(this.wind);
        this.showGeom(this.road);
        this.showGeom(this.building);
        this.hideGeom(this.pointCloud);
        this.showGeom(this.ground);

        let r = 48/255;
        let g = 152/255;
        let b = 166/255;

        this.human.material.color.r = 0.666;
        this.human.material.color.g = 0.666;
        this.human.material.color.b = 0.666;

        let delay;
        if(isPrev) delay = .1;
        else delay = .3;

        TweenMax.delayedCall(2.0, ()=>{
            //sensor
            for( let i = 0, len = this.animeList.length; i < len; i++ ){
                let anime = this.animeList[i];
                let action = anime.action;
                let mixer = anime.mixer;

                if(anime.name === "sensor"){
                    mixer.time = 0;
                    action.paused = false;
                    action.reset().play();
                }
            }

            this.container.add(this.sensor);


            TweenMax.killTweensOf(this.human.material.color);
            TweenMax.to(this.human.material.color, 1.5, {delay:1, r:r,g:g,b:b, ease:Quad.easeOut});
        });

        TweenMax.delayedCall(delay, ()=>{
            //car
            let dr = 2;
            let delay = .6;

            /*
                止まる位置
                x: 182.3904571533203, y: 0, z: -135.74269104003906
            */

            let carDefX = 218;
            let carDefY = 0;
            let carDefZ = -136.1;

            this.car.position.x = carDefX;
            this.car.position.y = carDefY;
            this.car.position.z = carDefZ;
            TweenMax.killTweensOf([this.car.position, this.wind.position, this.carShadow.position, this.sensor.position]);
            TweenMax.fromTo([this.car.position, this.wind.position, this.sensor.position], dr, {x:carDefX, y:carDefY, z:carDefZ}, {delay:delay, x:this.currentCarPosVector.x, y:this.currentCarPosVector.y, z:this.currentCarPosVector.z, ease:Quad.easeOut});
            TweenMax.fromTo([this.carShadow.position], dr, {x:carDefX, y:this.carShadowY, z:carDefZ}, {delay:delay, x:this.currentCarPosVector.x, z:this.currentCarPosVector.z, ease:Quad.easeOut});


            //wheel
            TweenMax.killTweensOf(this);
            TweenMax.to(this, dr, {delay:delay, currentWheelSpeed:0, ease:Quad.easeOut, onUpdate:()=>{
                this.wheelsDeg += this.currentWheelSpeed;
                let wheelsRad = this.pack.d2r(this.wheelsDeg);
                for( let i = 0, len = this.carTyres.length; i < len; i++ ){
                    let mesh = this.carTyres[i];
                    if(i === 0 || i === 2) mesh.rotation.x = wheelsRad;
                    else mesh.rotation.x = -wheelsRad;
                }
            }});
        });


        this.changeToDefColor();
    }

    startScene4(isPrev){
        //点群モード
        this.carCameraTarget.position.x = this.SCENE4_7_CAMERA_INFO.targetX;
        this.carCameraTarget.position.y = this.SCENE4_7_CAMERA_INFO.targetY;
        this.carCameraTarget.position.z = this.SCENE4_7_CAMERA_INFO.targetZ;

        this.cameraLookAtTarget.position.x = this.SCENE4_7_CAMERA_INFO.lookAtTargetX;
        this.cameraLookAtTarget.position.y = this.SCENE4_7_CAMERA_INFO.lookAtTargetY;
        this.cameraLookAtTarget.position.z = this.SCENE4_7_CAMERA_INFO.lookAtTargetZ;

        this.hideGeom(this.building);
        this.showGeom(this.ground);
        this.container.remove(this.sensor);

        this.showGeom(this.pointCloud, .3);

        TweenMax.killTweensOf(this);
        this.currentWheelSpeed = this.wheelSpeed;

        this.changeToDarkColor();

        for( let i = 0, len = this.animeList.length; i < len; i++ ) {
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "mainRout"){
                action.paused = false;
                mixer.setTime(0);
                action.reset().play();
                action.paused = true;
            }
        }
    }

    startScene5(isPrev){
        this.carCameraTarget.position.y = this.SCENE4_7_CAMERA_INFO.targetY + 30;
        this.carCameraTarget.position.z = this.SCENE4_7_CAMERA_INFO.targetZ + 30;

        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.hideGeom(item);
        }
        this.hideGeom(this.carWire);

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "car" || anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0) {
                if(isPrev){
                    action.paused = false;
                    mixer.setTime(anime.offsetTime);
                    action.reset().play();
                    action.paused = true;
                }
            }else if(anime.name === "mainRout"){
                if(!isPrev){
                    action.paused = false;
                    action.reset().play();
                }
            }else if(anime.name === "dashedRout"){
                action.paused = false;
                mixer.setTime(0);
                action.reset().play();
                action.paused = true;
            }
        }
    }

    startScene6(isPrev){

        this.carCameraTarget.position.y = this.SCENE4_7_CAMERA_INFO.targetY + 60;
        this.carCameraTarget.position.z = this.SCENE4_7_CAMERA_INFO.targetZ + 60;


        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.showGeom(item);
        }
        this.showGeom(this.carWire);

        this.hideGeom(this.building);
        this.showGeom(this.pointCloud, .3);
        this.container.remove(this.sensor07);

        this.changeToDarkColor();

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "car" || anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0) {
                if(!isPrev){
                    action.paused = false;
                    mixer.setTime(anime.offsetTime);
                    action.play();
                }
            }else if(anime.name === "dashedRout"){
                if(!isPrev){
                    action.paused = false;
                    action.reset().play();
                }
            }
        }

    }

    startScene7(isPrev){
        //ソリッド

        this.carCameraTarget.position.x = this.SCENE4_7_CAMERA_INFO.targetX;
        this.carCameraTarget.position.y = this.SCENE4_7_CAMERA_INFO.targetY + 60;
        this.carCameraTarget.position.z = this.SCENE4_7_CAMERA_INFO.targetZ + 60;

        this.cameraLookAtTarget.position.x = this.SCENE4_7_CAMERA_INFO.lookAtTargetX;
        this.cameraLookAtTarget.position.y = this.SCENE4_7_CAMERA_INFO.lookAtTargetY;
        this.cameraLookAtTarget.position.z = this.SCENE4_7_CAMERA_INFO.lookAtTargetZ;

        this.showGeom(this.ground);
        this.showGeom(this.building);
        this.showGeom(this.human);
        this.hideGeom(this.pointCloud);
        this.container.add(this.sensor07);
        // this.container.remove(this.wind);

        this.changeToDefColor();

/*        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let item = this.carWireClones[i];
            this.showGeom(item);
        }
        this.showGeom(this.carWire);*/

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "mainRout" || anime.name === "dashedRout"){
                action.paused = false;
                mixer.setTime(anime.duration);
                action.play();
                action.paused = true;
            }else if(anime.name === "sensor07"){
                mixer.time = 0;
                action.paused = false;
                action.reset().play();
            }
        }
    }

    startScene8(isPrev){
        this.enabledUpdate = true;
        TweenMax.to(this.canvas, 1, {opacity:1, ease:Quad.easeOut});

        this.carCameraTarget.position.x = -6;
        this.carCameraTarget.position.z = -20;

        if(this.sw >= this.pack.BP){
            this.carCameraTarget.position.y = 4.5;
            this.cameraLookAtTarget.position.x = -5.0;
            this.cameraLookAtTarget.position.y = -0.2;
            this.cameraLookAtTarget.position.z = -7.0;

        }else{
            this.carCameraTarget.position.y = 1.5;
            this.cameraLookAtTarget.position.x = 0;
            this.cameraLookAtTarget.position.y = -0.7;
            this.cameraLookAtTarget.position.z = -9.0;
        }

        this.showGeom(this.ground);
        this.hideGeom(this.human);
        this.container.remove(this.sensor07);

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let action = anime.action;
            let mixer = anime.mixer;

            if(anime.name === "car" || anime.name === "carWire" || anime.name.indexOf("carWireClone") === 0) {
                if(isPrev){
                    action.paused = false;
                    mixer.setTime(anime.offsetTime);
                    action.play();
                }
            }else if(anime.name === "mainRout" || anime.name === "dashedRout"){
                action.paused = false;
                mixer.setTime(0);
                action.play();
                action.paused = true;
            }
        }
    }

    startScene9(isPrev){
        this.enabledUpdate = false;
        TweenMax.to(this.canvas, .5, {opacity:0, ease:Quad.easeOut});
    }

    changeToDefColor(){
        let dr = 1;
        let ease = Quad.easeOut;

        TweenMax.killTweensOf(this.ground.material.color);
        TweenMax.killTweensOf(this.road.material.color);
        TweenMax.to(this.ground.material.color, dr, {r:this.defGroundColor.r, g:this.defGroundColor.g, b:this.defGroundColor.b, ease:ease});
        TweenMax.to(this.road.material.color, dr, {r:this.defRoadColor.r, g:this.defRoadColor.g, b:this.defRoadColor.b, ease:ease});
        TweenMax.to(this.ambientLight, dr, {intensity:ambientIntensity, ease:ease});

        this.car.traverse((child) => {
            if(child.isMesh) {
                child.material.blending = THREE.NormalBlending;
            }
        });
    }

    changeToDarkColor(){
        let dr = 1;
        let ease = Quad.easeOut;

        TweenMax.killTweensOf(this.ground.material.color);
        TweenMax.killTweensOf(this.road.material.color);

        let groundRGB = this.pack.hex2NormalRGB(this.POINTS_GROUND_COLOR);
        let roadRGB = this.pack.hex2NormalRGB(this.POINTS_ROAD_COLOR);


        TweenMax.to(this.ground.material.color, dr, {r:groundRGB.r, g:groundRGB.g, b:groundRGB.b, ease:ease});
        TweenMax.to(this.road.material.color, dr, {r:roadRGB.r, g:roadRGB.g, b:roadRGB.b, ease:ease});
        TweenMax.to(this.ambientLight, dr, {intensity:0, ease:ease});

        this.car.traverse((child) => {
            if(child.isMesh) {
                child.material.blending = THREE.AdditiveBlending;
            }
        });
    }

    scrollHandler(){

    }

    render(){
        this.renderer.render(this.scene, this.camera);
    }

    enterframe(){

    }

    updateEnvironment (envMap) {
        // this.traverseMaterials(this.container, (material) => {
        this.traverseMaterials(this.car, (material) => {

            // trace(material);
            if (material.isMeshStandardMaterial || material.isGLTFSpecularGlossinessMaterial) {
                material.envMap = envMap;
                material.needsUpdate = true;
            }
        });
    }

    traverseMaterials (object, callback) {
        object.traverse((node) => {
            if (!node.isMesh) return;
            const materials = Array.isArray(node.material)
                ? node.material
                : [node.material];
            materials.forEach(callback);
        });
    }

    updateObject(node, position, target){
        node.position.x = position.x;
        node.position.y = position.y;
        node.position.z = position.z;
        node.rotation.x = target.rotation.x;
        node.rotation.y = target.rotation.y;
        node.rotation.z = target.rotation.z;
    }

    enterframeThinOut(){
        if(this.pack.header.isMenuOpen) return;
        if(!this.enabledUpdate) return;
        if(this.enabledOrbitControls) this.controls.update();
        if(!this.loaded) return;

        let delta = this.clock.getDelta();

        for( let i = 0, len = this.animeList.length; i < len; i++ ){
            let anime = this.animeList[i];
            let mixer = this.animeList[i].mixer;
            let name = this.animeList[i].name;

            if(name === "sensor") {
                if (this.currentScene === 3) {
                    mixer.update(delta);
                    let opacity = (1 - (mixer.time % anime.duration / anime.duration)) * .7;

                    for (let i = 0, len = this.sensorMeshMaterials.length; i < len; i++) {
                        let m = this.sensorMeshMaterials[i];
                        m.opacity = opacity;
                    }
                }
            }else if(name === "mainRout" || name === "dashedRout") {
                if(this.currentScene === 5) {
                    if(mixer.time < 2) mixer.update(delta / 2);
                }else if(this.currentScene === 6 ) mixer.update(delta / 2);
            }else if(name === "sensor07") {
                if(this.currentScene === 7){
                    mixer.update(delta);
                    let opacity = (1 - (mixer.time % anime.duration / anime.duration)) * .3;
                    for( let i = 0, len = this.sensor07MeshMaterials.length; i < len; i++ ){
                        let m = this.sensor07MeshMaterials[i];
                        m.opacity = opacity;
                    }
                }
            }else if(name === "wind") {
                if(this.currentScene === 2){
                    mixer.update(delta);
                }
            }else if(name.indexOf("carWire") > -1) {
                if(this.currentScene === 1 || this.currentScene === 6 || this.currentScene === 7 || this.currentScene === 8){
                    mixer.update(delta);
                }
            }else{
                //car
                mixer.update(delta);
            }
        }

        //carアニメーション位置情報
        let carPosVector = new THREE.Vector3();
        carPosVector = this.currentCarPosVector = this.cameraTarget.getWorldPosition(carPosVector);

        //carWire位置アニメーション
        let carWireAdjustY = .7;
        let carWireVector = new THREE.Vector3();
        carWireVector = this.carWireTarget.getWorldPosition(carWireVector);
        carWireVector.y += carWireAdjustY;
        this.updateObject(this.carWire, carWireVector, this.carWireTarget);

        for( let i = 0, len = this.CAR_WIRE_CLONE_LEN; i < len; i++ ){
            let id = i + 1;
            let target = this["carWireTarget" + id];
            let mesh = this.carWireClones[i];
            let carWireVector = new THREE.Vector3();

            carWireVector = target.getWorldPosition(carWireVector);
            carWireVector.y += carWireAdjustY;

            this.updateObject(mesh, carWireVector, target);
        }

        //carアニメーション
        if(this.carShadow.material.opacity < 1) this.carShadow.material.opacity = 1;        //なぜかopacityが0になってしまうので1にする
        if(this.currentScene !== 3){
            this.updateObject(this.car, carPosVector, this.cameraTarget.parent);
            //carアニメーションに同期させる
            this.updateObject(this.carShadow, carPosVector, this.cameraTarget.parent);
            this.carShadow.position.y = this.carShadowY;

            this.updateObject(this.wind, carPosVector, this.cameraTarget.parent);
            this.updateObject(this.sensor, carPosVector, this.cameraTarget.parent);
            this.updateObject(this.sensor07, carPosVector, this.cameraTarget.parent);
            this.sensor07.position.y = 0.2;
        }

        let cameraTargetPosVector = new THREE.Vector3();
        cameraTargetPosVector = this.carCameraTarget.getWorldPosition(cameraTargetPosVector);

        let targetPositionRightNow = new THREE.Vector3();
        let targetLookAt;
        //カメラのcarの追従
        if(!this.enabledOrbitControls){
            if(this.currentScene === 3){
                let cameraLookAtTargetPosVector = new THREE.Vector3();
                targetPositionRightNow.x = this.cameraTargetPosVectorForScene3.x;
                targetPositionRightNow.y = this.cameraTargetPosVectorForScene3.y;
                targetPositionRightNow.z = this.cameraTargetPosVectorForScene3.z;
                targetLookAt = this.cameraLookAtTargetPosVectorForScene3;
            }else if(this.currentScene === 8){
                let cameraLookAtTargetPosVector = new THREE.Vector3();
                cameraLookAtTargetPosVector = this.cameraLookAtTarget.getWorldPosition(cameraLookAtTargetPosVector);

                targetPositionRightNow.x = cameraTargetPosVector.x;
                targetPositionRightNow.y = cameraTargetPosVector.y;
                targetPositionRightNow.z = cameraTargetPosVector.z;
                targetLookAt = cameraLookAtTargetPosVector;
            }else{
                let cameraLookAtTargetPosVector = new THREE.Vector3();
                cameraLookAtTargetPosVector = this.cameraLookAtTarget.getWorldPosition(cameraLookAtTargetPosVector);

                targetPositionRightNow.x = cameraTargetPosVector.x;
                targetPositionRightNow.y = cameraTargetPosVector.y;
                targetPositionRightNow.z = cameraTargetPosVector.z;
                targetLookAt = cameraLookAtTargetPosVector;
            }
        }

        let ease = 15;
        let mouseX;
        let mouseY;

        if(this.currentScene === 1 || this.currentScene === 4 || this.currentScene === 5 || this.currentScene === 6 || this.currentScene === 7){
            mouseX = this.mouseStage.x / 70;
            mouseY = this.mouseStage.y / 70;
        }else if(this.currentScene === 2 || this.currentScene === 3 || this.currentScene === 8){
            mouseX = this.mouseStage.x / 250;
            mouseY = this.mouseStage.y / 250;
        }else{
            mouseX = 0;
            mouseY = 0;
        }

        this.currentTargetPosition.x += (targetPositionRightNow.x - this.currentTargetPosition.x) / 3;
        this.currentTargetPosition.y += (targetPositionRightNow.y - this.currentTargetPosition.y) / 3;
        this.currentTargetPosition.z += (targetPositionRightNow.z - this.currentTargetPosition.z) / 3;

        this.camera.position.x += ((this.currentTargetPosition.x) - this.camera.position.x + mouseX) / ease;
        this.camera.position.y += ((this.currentTargetPosition.y) - this.camera.position.y + mouseY) / ease;
        this.camera.position.z += ((this.currentTargetPosition.z) - this.camera.position.z) / ease;

        this.currentLookatTargetPosition.x += (targetLookAt.x - this.currentLookatTargetPosition.x) / ease;
        this.currentLookatTargetPosition.y += (targetLookAt.y - this.currentLookatTargetPosition.y) / ease;
        this.currentLookatTargetPosition.z += (targetLookAt.z - this.currentLookatTargetPosition.z) / ease;

        this.camera.lookAt(this.currentLookatTargetPosition);

        //car wheels
        if(this.currentScene !== 3){
            this.wheelsDeg += this.currentWheelSpeed;
            let wheelsRad = this.pack.d2r(this.wheelsDeg);
            for( let i = 0, len = this.carTyres.length; i < len; i++ ){
                let mesh = this.carTyres[i];
                if(i === 0 || i === 2) mesh.rotation.x = wheelsRad;
                else mesh.rotation.x = -wheelsRad;
            }
        }

        this.render();
        if(this.enabledStats) this.stats.update();
    }

    executeResize() {
        super.executeResize();

        if(!this.canvas) return;
        this.width = window.innerWidth;
        this.height = window.innerHeight;
        this.canvas.width = this.width;
        this.canvas.height = this.height;

        if(!this.camera) return;
        this.camera.aspect = this.width / this.height;
        this.camera.updateProjectionMatrix();
        this.renderer.setSize(this.width, this.height);
    }
}
