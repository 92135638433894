import TemplateContents from './TemplateContents';

export default class extends TemplateContents{
    constructor(param){
        super(param);
    }

    init() {
        super.init(true);
        this.resizeEvent = 'resize';

        this.initEvents();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();

        this.loaded = false;
        this.per = 0;
        this.showPer = 0;
        this.PI = Math.PI;
        this.defStrokeDashoffsetPC = 242 * this.PI;
        this.defStrokeDashoffsetSP = 160 * this.PI;
    }

    setDom(){
        super.setDom();

        this.loading = document.querySelector('#loading');
        this.circleDashed = document.querySelector('#loading .dashed_circle');
        this.circleSVG = document.querySelector('#loading svg');
        this.circle = document.querySelector('#loading svg circle');
        this.text = document.querySelector('#loading .text');
    }

    initEvents(){
        super.initEvents();
    }

    start(){

    }

    fadeOut(){
        TweenMax.to(this.loading, .6, {delay:.5, opacity:0, ease:Quad.easeIn, onComplete:()=>{
            this.loading.remove();
            this.destruct();
        }});
    }

    scrollHandler(){

    }


    enterframe(){

    }

    enterframeThinOut(){
        if(this.loaded) return;
        this.showPer += 3;
        this.per += (this.pack.hero.getPercent() - this.per) * .3;

        if(this.showPer >= 100) this.showPer = 100;

        let per = Math.min(this.per, this.showPer);

        let multiply;
        let offset;
        if(this.sw >= this.pack.BP) {
            multiply = 2.42;
            offset = this.defStrokeDashoffsetPC;
        }else {
            multiply = 1.6;
            offset = this.defStrokeDashoffsetSP;
        }

        this.circle.style.strokeDashoffset = offset - per * multiply * this.PI;

        if(this.showPer >= 100 && this.per >= 99 && this.pack.common.loadedContentful && this.pack.hero.loaded){
            this.loaded = true;
            this.fadeOut();
            this.dispatchEvent("complete");
        }
    }

    executeResize() {
        super.executeResize();

        this.circleDashed.style.top = this.shh + "px";
        this.circleSVG.style.top = this.shh + "px";
        this.text.style.top = this.shh + "px";
    }
}
