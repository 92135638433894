import Platform from './Platform';

export default class extends Platform{
    constructor(param){
        super(param);
    }

    init() {
        super.init();

        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    reset(){
        super.reset();

        this.setVars();
        // this.pack.common.addScrollTarget(this);
        // this.pack.common.addEnterframeTarget(this);
    }

    destruct(){
        super.destruct();

        // this.pack.common.removeScrollTarget(this);
        // this.pack.common.removeEnterframeTarget(this);
    }

    setVars(){
        super.setVars();
    }

    setDom(){
        super.setDom();
    }

    initEvents(){
        super.initEvents();
    }

    start(){
        super.start();
        this.setEachSection(this.pack.apiJSONList[4].items, "operationPlatformDevopsProcessName", "operationPlatformDevopsProcessHeadline");
        this.setValues(this.pack.apiJSONList[5].items, this.pack.apiJSONOperationPlatformList);
        for( let i = 0, len = this.insertSectionLen; i < len; i++ ){
            let contentsID = i + 1;
            this.setSection(contentsID);
        }
        this.sectionH3List = document.querySelectorAll('main.platform .sections .section h3');
        this.pack.setReleaseNotes(this.releaseNotesContainer);
    }

    scrollHandler(){
        super.scrollHandler();
    }


    enterframe(){

    }

    enterframeThinOut(){

    }

    executeResize() {
        super.executeResize();
        this.sectionsForLocalNav.style.height = this.sections.clientHeight - this.sh - 174 + "px";
    }
}