import {ua} from './libs/daijima/ua';

/*
環境変数からの取得
CONTENTFUL_API_BRANCH
CONTENTFUL_API_SPACES
CONTENTFUL_API_ACCESS_TOKEN
CONTACT_API_PATH
DOCS_JA_API_PATH
DOCS_EN_API_PATH
*/

export default class {
    constructor(target, targetPackageName) {
        this.init(target, targetPackageName);
        return this.obj;
    }

    getTargetPackage(){
        return this.obj;
    }

    init(target, targetPackageName){
        let obj = target[targetPackageName] = target[targetPackageName] || {};
        this.obj = obj;
        obj.BP = 768;
        obj.ua = ua;
        obj.hasTouch = ("ontouchstart" in window);
        obj.pageYOffset = 0;
        obj.pastPageYOffset = 0;
        obj.scrollWrapper = document.querySelector('.scroll-wrapper');

        obj.CONTACT_API_PATH = process.env.CONTACT_API_PATH;

        obj.CONTENTFUL_API_SPACES = process.env.CONTENTFUL_API_SPACES;
        obj.CONTENTFUL_API_ACCESS_TOKEN = process.env.CONTENTFUL_API_ACCESS_TOKEN;
        obj.CONTENTFUL_API_BRANCH = process.env.CONTENTFUL_API_BRANCH;
        obj.CONTENTFUL_API_PATH_LIST = [
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=meetOurTeam&limit=8&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                                  //Meet Our Team
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=ourPartners&limit=8&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                                  //Our Partner
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=developmentPlatformDevopsProcess&order=fields.displayOrder&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,           //Development Platform Devops Process
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=developmentPlatformDetails&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                           //Development Platform カルーセル内情報
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=operationPlatformDevopsProcess&order=fields.displayOrder&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,             //Operation Platform Devops Process
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=operationPlatformDetails&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                             //Operation Platform カルーセル内情報
            "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=contactInfo&order=fields.displayOrder&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN,                                //Contact
        ];


        // obj.DOCS_EN_API_PATH = "/json_dev/recent.en.json";                                                          //for dev
        obj.DOCS_JA_API_PATH = process.env.DOCS_JA_API_PATH;          //ja
        obj.DOCS_EN_API_PATH = process.env.DOCS_EN_API_PATH;          //en

        obj.getContentfulAPIPlatformSingleItemPath = (pageID, id) => {
            let path, pageName;
            if(pageID === 0) pageName = "developmentPlatform";
            else pageName = "operationPlatform";
            path = "https://cdn.contentful.com/spaces/" + obj.CONTENTFUL_API_SPACES + "/environments/" + obj.CONTENTFUL_API_BRANCH + "/entries?content_type=" + pageName + "&fields.devopsProcess.sys.contentType.sys.id=" + pageName + "DevopsProcess&fields.devopsProcess.sys.id=" + id + "&order=fields.displayOrder&access_token=" + obj.CONTENTFUL_API_ACCESS_TOKEN;
            return path;
        };

        obj.getContentfulAPIImgAccessPath = (id) => {
            return `https://cdn.contentful.com/spaces/` + obj.CONTENTFUL_API_SPACES + `/environments/` + obj.CONTENTFUL_API_BRANCH + `/assets/${id}?access_token=` + obj.CONTENTFUL_API_ACCESS_TOKEN;
        };

        obj.apiJSONList;
        obj.apiJSONDevelopPlatformList;
        obj.apiJSONOperationPlatformList;
        obj.LANG = document.documentElement.lang;        //ja or en

        obj.setLocalStorage = (value) => {
            // trace(value);
            // document.cookie = 'lang=' + value;
            localStorage.setItem('lang', value);
        }

        obj.getName = (value) => {
            let name;
            if(obj.LANG === "en") {
                name = value.nameEn;
            }else if(obj.LANG === "ja") {
                name = value.nameJa;
            }else {
                name = value.nameCn;
            }
            return name;
        };

        obj.getLocation = (value) => {
            let location;
            if(obj.LANG === "en") {
                location = value.locationEn;
            }else if(obj.LANG === "ja") {
                location = value.locationJa;
            }else {
                location = value.locationCn;
            }
            return location;
        };

        obj.getPosition = (value) => {
            let position;
            if(obj.LANG === "en") {
                position = value.postionEn;
            }else if(obj.LANG === "ja") {
                position = value.postionJa;
            }else {
                position = value.postionCn;
            }
            return position;
        };

        obj.getTitle = (value) => {
            let title;
            if(obj.LANG === "en") {
                title = value.titleEn;
            }else if(obj.LANG === "ja") {
                title = value.titleJa;
            }else {
                title = value.titleCn;
            }
            return title;
        };

        obj.getBody = (value) => {
            let body;
            if(obj.LANG === "en") {
                body = value.bodyEn;
            }else if(obj.LANG === "ja") {
                body = value.bodyJa;
            }else {
                body = value.bodyCn;
            }
            return body;
        };

        obj.getDescription = (value) => {
            let description;
            if(obj.LANG === "en") {
                description = value.descriptionEn;
            }else {
                description = value.descriptionJa;
            }

            return description;
        };

        obj.getData = (value, name) => {
            let data;
            if(obj.LANG === "en") {
                data = value[name + "En"];
            }else {
                data = value[name + "Ja"];
            }

            return data;
        };


        obj.changeLang = (value) =>{
            let href;
            if(obj.LANG === "en") href = location.pathname + location.search;
            else href = location.href.split("/ja")[1];

            if(value === "jp") href = "/ja" + href;
            location.href = href;
        };

        obj.getFormatDate = (date) => {
            let dateArray = String(new Date(date)).split(" ");
            return dateArray[1] + " " + dateArray[2] + ", " + dateArray[3];
        };

        obj.setReleaseNotes = (container) => {
            let items = obj.apiJSONList[7].release_notes;
            let src = "";

            if(items === undefined) return;
            let len = (items.length >= 3) ? 3 : items.length;

            for( let i = 0; i < len; i++ ){
                let item = items[i];
                let html = obj.getReleaseNotesHTML(item);
                src += html;
            }

            container.innerHTML = src;
        };

        obj.getReleaseNotesHTML = (value) =>{
            const slug = value.slug.split('/').splice(obj.LANG === 'ja' ? 2 : 1).join('/');
            let url = 'https://docs.web.auto/' + slug;
            let date = obj.getFormatDate(value.published_at);
            let product_name = value.title;
            let description = value.description;
            let addTxtClass = '';
            if(obj.LANG === 'ja') addTxtClass = ' font2 txt_ja';

            return `
                <li>
                    <a href="${url}" target="_blank">
                        <div class="li_child">
                            <div class="left">
                                <div class="date">${date}</div>
                            </div>
                            <div class="right">
                                <div class="product_name">${product_name}</div>
                                <p class="txt${addTxtClass}">${description}</p>
                            </div>
                        </div>
                    </a>
                </li>
            `;
        };

        // 3桁カンマ区切りとする
        obj.addComma = (num) => {
            var s = String(num).split('.');
            var ret = String(s[0]).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            if (s.length > 1) {
                ret += '.' + s[1];
            }
            return ret;
        };

        obj.hex2RGB = ( h ) => {
            let r = Math.floor(h / 65536);
            let g = Math.floor( ( h % 65536) / 256);
            let b = h % 256;

            return {r:r, g:g, b:b};
        };

        obj.hex2NormalRGB = ( h ) => {
            let r = Math.floor(h / 65536);
            let g = Math.floor( ( h % 65536) / 256);
            let b = h % 256;

            return {r:r/255, g:g/255, b:b/255};
        };

        obj.d2r = function(d){
            return d * Math.PI / 180;
        };

        obj.r2d = function(r){
            return r * 180 / Math.PI;
        };

        obj.random = function(min, max){
            return Math.random() * (max - min) + min;
        };

        obj.checkBottom = () => {
            return (obj.pageYOffset + 1) >= (document.body.clientHeight - obj.common.sh);
        };

        obj.lockHTML = ()=>{
            let html = document.documentElement;
            html.classList.add("hidden");
        };

        obj.unlockHTML = ()=>{
            let html = document.documentElement;
            html.classList.remove("hidden");
        };

        obj.anchorScroll = function(targetY, dr, ease, cb){
            if(!dr) dr = 1;
            if(!ease) ease = Quart.easeInOut;
            let obj = {y:window.pageYOffset};
            TweenMax.killTweensOf(obj);

            TweenMax.to(obj, dr, {y:targetY, ease:ease, onUpdate:function(){
                window.scrollTo(0, obj.y);
            }, onComplete:function(){
                if(cb) cb();
            }});
        };

        obj.commonParallax = function(item, targetY, defY, addCode, moveY){
            let top = item.getBoundingClientRect().top;
            if(defY === undefined) defY = 200;

            let speed = (item.dataset.speed != "undefined")  ? item.dataset.speed : "5";
            let difY = (top - targetY) / speed + defY;  //スタート地点はデフォルトでは200px下げる
            if(addCode == undefined) addCode = '';

            if(moveY){
                if(difY < defY - moveY) difY = defY - moveY;
            }

            if(top < targetY) {
                item.style.transform = 'translate3d(0,' + difY + 'px, 0)' + addCode;
                // item.style.top = difY + 'px';
            }
        };

        obj.addZero = function(str){
            if(str.length == 1) str = "0"+str;
            return str;
        };

        /**
         * 文字列をキャメルケースへ変換
         * @param {string} str 変換する文字列
         * @param {boolean} [upper] 文字列の先頭も大文字にするかどうか
         * @return {string} 変換された文字列を返す
         */
        obj.toCamelcase = (str, upper) => {
            if (typeof str !== 'string') return str;

            var strs = str.split(/[-_ ]+/), i = 1, len = strs.length;

            if (len <= 1) return str;

            if (upper) {
                i = 0;
                str = '';
            } else {
                str = strs[0].toLowerCase();
            }

            for (; i < len; i++) {
                str += strs[i].toLowerCase().replace(/^[a-z]/, function(value) {
                    return value.toUpperCase();
                });
            }

            return str;
        };
    }
}
